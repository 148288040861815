import * as actions from './constant';
const initialState = {
  isChangePassword: false,
  error: false,
  loading: false,
  message: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.CHANGEPASSWORD_INITIATED:
      return {
        ...state,
        isChangePassword: false,
        message: false,
        error: false,
        loading: true
      };
    case actions.CHANGEPASSWORD_SUCCESS:
      return {
        ...state,
        isChangePassword: true,
        loading: false,
        error: false,
        message: 'password changed'
      };
    case actions.CHANGEPASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
        isChangePassword: false
      };
    default:
      return state;
  }
};
