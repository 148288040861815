import * as actions from './constant';
const initialState = {
  status: false,
  error: false,

  message: false,
  isTeamAdded: false,
  deleteStatus: false,
  setEdit: false,
  teamToEdit: {},
  teams: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.GETTEAM_INITIATED:
      return {
        ...state,
        teams: [],
        message: false,
        error: false
      };
    case actions.GETTEAM_SUCCESS:
      return {
        ...state,
        teams: action.payload,
        error: false,
        message: 'get team data'
      };
    case actions.GETTEAM_ERROR:
      return {
        ...state,
        error: true,
        message: action.error,
        teams: []
      };
    case actions.ADDTEAM_INITIATED:
      return {
        ...state,
        isTeamAdded: false,
        message: false,
        error: false
      };
    case actions.ADDTEAM_SUCCESS:
      return {
        ...state,
        isTeamAdded: true,
        error: false,
        message: 'Team added'
      };
    case actions.ADDTEAM_ERROR:
      return {
        ...state,
        error: true,
        message: action.error,
        isItemAdded: false
      };
    case actions.DELETETEAM_INITIATED:
      return {
        ...state,
        deleteStatus: false
      };
    case actions.DELETETEAM_SUCCESS:
      return {
        ...state,
        deleteStatus: true
      };
    case actions.DELETETEAM_ERROR:
      return {
        ...state,
        deleteStatus: false
      };
    case actions.UPDATETEAM_INITIATED:
      return {
        ...state,
        status: false,
        message: false,
        error: false
      };
    case actions.UPDATETEAM_SUCCESS:
      return {
        ...state,
        status: true,
        message: false,
        error: false
      };
    case actions.UPDATETEAM_ERROR:
      return {
        ...state,
        status: false,
        error: true,
        message: action.error
      };
    case actions.OPEN_EDIT:
      return {
        ...state,
        teamToEdit: action.payload
      };
    case actions.INVITEMEMBER_INITIATED:
      return {
        ...state,
        status: false,
        message: false,
        error: false
      };
    case actions.INVITEMEMBER_SUCCESS:
      return {
        ...state,
        status: true,
        message: false,
        error: false
      };
    case actions.INVITEMEMBER_ERROR:
      return {
        ...state,
        status: false,
        error: true,
        message: action.error
      };
    default:
      return state;
  }
};
