import * as actions from './constant';
const initialState = {
  status: false,
  multiJobId: null,
  isCouriersList: false,
  error: false,
  loading: false,
  message: false,
  isParcelAdded: false,
  isItemAdded: false,
  isParcelDelete: false,
  isParcelUpdate: false,
  isNextDaySevices: false,
  isItemDelete: false,
  isItemUpdate: false,
  editAddressData: {},
  editParcelData: {},
  parcels: [],
  jobDetails: {},
  quotaDetails: {},
  nextDayEstimate: {},
  parcelforEdit: {},
  nextDayQuote: {},
  parcelStores: {},
  currentJobs: [],
  multiCurrentAddress: null,
  filteredData: [],
  parcelAllAddress: [],
  addSelectedJobIdParcel: '',
  selectedJobIdForParcel: [],
  CSVStatus: [],
  lastParcelAddress: [],
  isClicked: false,
  publicCouriersNearMe: [],
  privateCouriersNearMe: [],
  optimisedSequence: [],
  workingHours: [],
  updatedCourier: false,
  sameDayJobDetails: {},
  changedJobType: 0,
  waitandreturn: false,
  pickuppayload: {},
  jobManifestData: {},
  jobLocationData: {},
  rateCourierData: {},
  showTimerfromPubnub: {},
  showFeedBackfromPubnub: {},
  showLoaderfromPubnub: {},
  sameDayLabels: [],
  labelZipFile: [],
};
export default (state = initialState, action) => {
  switch (action.type) {
    case actions.BOOKING_INITIATED:
      return {
        ...state,
        status: false,
        message: false,
        error: false,
        loading: true,
      };
    case actions.BOOKING_SUCCESS:
      return {
        ...state,
        status: true,
        message: 'Booking Added',
      };
    case actions.BOOKING_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
        status: false,
      };
    case actions.COURIERSLIST_INITIATED:
      return {
        ...state,
        isCouriersList: false,
        loading: true,
      };
    case actions.COURIERSLIST_SUCCESS:
      return {
        ...state,
        isCouriersList: true,
        loading: false,
        error: false,
        message: 'couriers list available',
      };
    case actions.COURIERSLIST_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
        isCouriersList: false,
      };
    case actions.GETJOB_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GETJOB_SUCCESS:
      return {
        ...state,
        jobDetails: action.payload,
        message: false,
        error: false,
        loading: false,
      };
    case actions.GETJOB_ERROR:
      return {
        ...state,
        jobDetails: {},
        loading: false,
        error: true,
        message: action.error,
      };
    case actions.UPDATEJOB_INITIATED:
      return {
        ...state,
        status: false,
        message: false,
        error: false,
        loading: true,
      };
    case actions.UPDATEJOB_SUCCESS:
      return {
        ...state,
        status: true,
        message: false,
        error: false,
        loading: false,
      };
    case actions.UPDATEJOB_ERROR:
      return {
        ...state,
        status: false,
        loading: false,
        error: true,
        message: action.error,
      };
    case actions.GETQUOTA_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GETQUOTA_SUCCESS:
      return {
        ...state,
        quotaDetails: action.payload,
        message: false,
        error: false,
        loading: false,
      };
    case actions.GETQUOTA_ERROR:
      return {
        ...state,
        quotaDetails: {},
        loading: false,
        error: true,
        message: action.error,
      };
    case actions.GETNEXTDAYQUOTE_INITIATED:
      return {
        ...state,
        nextDayQuote: {},
        message: false,
        error: false,
        loading: true,
      };
    case actions.GETNEXTDAYQUOTE_SUCCESS:
      return {
        ...state,
        nextDayQuote: action.payload,
        message: false,
        error: false,
        loading: false,
      };
    case actions.GETNEXTDAYQUOTE_ERROR:
      return {
        ...state,
        nextDayQuote: {},
        loading: false,
        error: true,
        message: action.error,
      };

    case actions.GET_NEXTDAYESTIMATE_INITIATED:
      return {
        ...state,
        // nextDayEstimate: {},
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_NEXTDAYESTIMATE_SUCCESS:
      return {
        ...state,
        nextDayEstimate: action.payload,
        error: false,
        loading: false,
        message: 'Next day estimate added',
      };
    case actions.GET_NEXTDAYESTIMATE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
        nextDayEstimate: {},
      };
    case actions.ADDPARCEL_INITIATED:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case actions.ADDPARCEL_SUCCESS:
      return {
        ...state,
        parcels: state.parcels.concat(action.payload),
        message: false,
        error: false,
        loading: false,
      };
    case actions.ADDPARCEL_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
      };
    case actions.CANCELJOB_INITIATED:
      return {
        ...state,
        error: false,
        loading: true,
      };
    case actions.CANCELJOB_SUCCESS:
      return {
        ...state,
        jobDetails: {},
        message: false,
        error: false,
        loading: false,
      };
    case actions.CANCELJOB_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
      };
    case actions.GETPARCEL_INITIATED:
      return {
        ...state,
        parcels: [],
        message: false,
        error: false,
        loading: true,
      };
    case actions.GETPARCEL_SUCCESS:
      return {
        ...state,
        parcels: action.payload,
        message: false,
        error: false,
        loading: false,
      };
    case actions.GETPARCEL_ERROR:
      return {
        ...state,
        parcels: [],
        loading: false,
        error: true,
        message: action.error,
      };

    case actions.UPDATEPARCEL_INITIATED:
      return {
        ...state,
        isParcelUpdate: false,
        message: false,
        error: false,
        loading: true,
      };
    case actions.UPDATEPARCEL_SUCCESS:
      return {
        ...state,
        isParcelUpdate: true,
        loading: false,
        error: false,
        message: 'Parcel added',
      };
    case actions.UPDATEPARCEL_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
        isParcelUpdate: false,
      };
    case actions.DELETEPARCEL_INITIATED:
      return {
        ...state,
        isParcelDelete: false,
        message: false,
        error: false,
        loading: true,
      };
    case actions.DELETEPARCEL_SUCCESS:
      return {
        ...state,
        isParcelDelete: true,
        message: false,
        error: false,
        loading: false,
      };
    case actions.DELETEPARCEL_ERROR:
      return {
        ...state,
        isParcelDelete: false,
        loading: false,
        error: true,
        message: action.error,
      };
    case actions.GETPARCELFOREDIT_INITIATED:
      return {
        ...state,
        parcelforEdit: {},
        message: false,
        error: false,
        loading: true,
      };
    case actions.GETPARCELFOREDIT_SUCCESS:
      return {
        ...state,
        parcelforEdit: action.payload,
        message: false,
        error: false,
        loading: false,
      };
    case actions.UPDATEPARCELFOREDIT_ERROR:
      return {
        ...state,
        parcelforEdit: {},
        loading: false,
        error: true,
        message: action.error,
      };

    case actions.PARCEL_INITIATED:
      return {
        ...state,
        isParcelAdded: false,
        message: false,
        error: false,
        loading: true,
      };
    case actions.PARCELADD_SUCCESS:
      return {
        ...state,
        isParcelAdded: true,
        loading: false,
        error: false,
        message: 'Parcel added',
      };
    case actions.PARCEL_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
        isParcelAdded: false,
      };
    case actions.ITEM_INITIATED:
      return {
        ...state,
        isItemAdded: false,
        message: false,
        error: false,
        loading: true,
      };
    case actions.ITEMADD_SUCCESS:
      return {
        ...state,
        isItemAdded: true,
        loading: false,
        error: false,
        message: 'Item added',
      };
    case actions.ITEM_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
        isItemAdded: false,
      };
    case actions.UPDATEITEM_INITIATED:
      return {
        ...state,
        isItemUpdate: false,
        message: false,
        error: false,
        loading: true,
      };
    case actions.UPDATEITEMADD_SUCCESS:
      return {
        ...state,
        isItemUpdate: true,
        loading: false,
        error: false,
        message: 'Item added',
      };
    case actions.UPDATEITEM_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
        isItemUpdate: false,
      };
    case actions.DELETEITEM_INITIATED:
      return {
        ...state,
        isItemDelete: false,
        message: false,
        error: false,
        loading: true,
      };
    case actions.DELETEITEM_SUCCESS:
      return {
        ...state,
        isItemDelete: true,
        message: false,
        error: false,
        loading: false,
      };
    case actions.DELETEITEM_ERROR:
      return {
        ...state,
        isItemDelete: false,
        loading: false,
        error: true,
        message: action.error,
      };
    case actions.SETNEXTDAYSERVICE_INITIATED:
      return {
        ...state,
        isNextDaySevices: false,
        message: false,
        error: false,
        loading: true,
      };
    case actions.SETNEXTDAYSERVICE_SUCCESS:
      return {
        ...state,
        isNextDaySevices: true,
        loading: false,
        error: false,
        message: 'next day sevices set',
      };
    case actions.SETNEXTDAYSERVICE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
        isNextDaySevices: false,
      };
    case actions.GETPARCELSTORES_INITIATED:
      return {
        ...state,
        parcelStores: {},
        message: false,
        error: false,
        loading: true,
      };
    case actions.GETPARCELSTORES_SUCCESS:
      return {
        ...state,
        parcelStores: action.payload,
        loading: false,
        error: false,
        message: 'get parcel stores',
      };
    case actions.GETPARCELSTORES_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
        parcelStores: {},
      };
    case actions.CLEARPARCELSTORES:
      return {
        ...state,
        parcelStores: {},
      };

    case actions.GETCURRENTJOB_INITIATED:
      return {
        ...state,
        currentJobs: [],
        message: false,
        error: false,
        loading: true,
      };
    case actions.GETCURRENTJOB_SUCCESS:
      return {
        ...state,
        currentJobs: action.payload,
        loading: false,
        error: false,
        message: 'get current-job data',
      };
    case actions.GETCURRENTJOB_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
        currentJobs: [],
      };
    case actions.REMOVEDRAFT:
      return {
        ...state,
        currentJobs: [...action.payload],
      };
    case actions.CONVERTMULTIJOB_INITIATED:
      return {
        ...state,
        multiJobId: null,
        message: false,
        error: false,
        loading: true,
      };
    case actions.CONVERTMULTIJOB_SUCCESS:
      return {
        ...state,
        multiJobId: action.payload.multi_job_id,
        loading: false,
        error: false,
        message: 'get current-job data',
      };
    case actions.CONVERTMULTIJOB_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
        multiJobId: null,
      };
    case actions.SET_MULTI_CURRENT_ADD:
      return {
        ...state,
        multiCurrentAddress: action.payload,
      };
    case actions.CLEAR_MULTI_CURRENT_ADD:
      return {
        ...state,
        multiCurrentAddress: null,
      };
    case actions.SET_FILTERED_DATA:
      return {
        ...state,
        filteredData: action.payload,
      };
    case actions.ADD_PARCEL_NEWADDRESS:
      return {
        ...state,
        parcelAllAddress: [action.payload],
      };
    case actions.ADD_SELECTED_JOB_ID_PARCEL:
      return {
        ...state,
        addSelectedJobIdParcel: action.payload,
      };
    case actions.EDIT_ADDRESS_DATA:
      return {
        ...state,
        editAddressData: { ...action.payload },
      };
    case actions.EDIT_PARCEL_DATA:
      return {
        ...state,
        editParcelData: { ...action.payload },
      };
    case actions.SELECTEDJOBID_PARCEL_ADD:
      return {
        ...state,
        selectedJobIdForParcel: action.payload,
      };
    case 'getCsvStatus':
      return {
        ...state,
        CSVStatus: action.payload,
      };
    case 'lastParcelAddress':
      return {
        ...state,
        lastParcelAddress: action.payload,
      };
    case 'ButtonClicked':
      return {
        ...state,
        isClicked: action.payload,
      };
    case actions.CONVERT_SAME_NEXT_DAY_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.CONVERT_SAME_NEXT_DAY_SUCCESS:
      return {
        ...state,
        message: false,
        error: false,
        loading: false,
      };
    case actions.CONVERT_SAME_NEXT_DAY_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
      };

    case actions.PRIVATE_GETCOURIERS_AROUND_ME_INITIATED:
      return {
        ...state,
        loading: false,
        error: false,
        privateCouriersNearMe: [],
      };
    case actions.PRIVATE_GETCOURIERS_AROUND_ME_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        privateCouriersNearMe: [...action.payload],
      };
    case actions.PRIVATE_GETCOURIERS_AROUND_ME_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
        privateCouriersNearMe: [],
      };
    case actions.PUBLIC_GETCOURIERS_AROUND_ME_INITIATED:
      return {
        ...state,
        loading: false,
        error: false,
        publicCouriersNearMe: [],
      };
    case actions.PUBLIC_GETCOURIERS_AROUND_ME_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        publicCouriersNearMe: [...action.payload],
      };
    case actions.PUBLIC_GETCOURIERS_AROUND_ME_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
        publicCouriersNearMe: [],
      };

    case actions.OPTIMISE_SEQUENCE_INITIATED:
      return {
        ...state,
        loading: true,
        error: false,
        optimisedSequence: [],
      };
    case actions.OPTIMISE_SEQUENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        optimisedSequence: [...action.payload],
      };
    case actions.OPTIMISE_SEQUENCE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
        optimisedSequence: [],
      };
    case actions.CHANGE_SEQUENCE_INITIATED:
      return {
        ...state,
        loading: true,
        error: false,
      };
    case actions.CHANGE_SEQUENCE_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
      };
    case actions.CHANGE_SEQUENCE_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
      };

    case actions.GET_WORKING_HOURS_INITIATED:
      return {
        ...state,
        loading: true,
        error: false,
        workingHours: [],
      };
    case actions.GET_WORKING_HOURS_SUCCESS:
      return {
        ...state,
        loading: false,
        error: false,
        workingHours: { ...action.payload },
      };
    case actions.UPDATE_COURIER:
      return { ...state, updatedCourier: action.payload };
    case actions.GET_WORKING_HOURS_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
        workingHours: [],
      };
    case actions.GETSAMEDAYJOB_INITIATED:
      return {
        ...state,
        message: false,
        error: false,
        loading: true,
      };
    case actions.GETSAMEDAYJOB_SUCCESS:
      return {
        ...state,
        sameDayJobDetails: action.payload,
        message: false,
        error: false,
        loading: false,
      };
    case actions.GETSAMEDAYJOB_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
      };
    case actions.CHANGEJOBTYPE:
      return {
        ...state,
        changedJobType: action.payload,
      };
    case actions.WAITANDRETURN:
      return {
        ...state,
        waitandreturn: action.payload,
      };
    case actions.PICKUPPAYLOAD:
      return {
        ...state,
        pickuppayload: action.payload,
      };
    case actions.JOB_COURIER_MANIFEST:
      return {
        ...state,
        jobManifestData: action.payload,
      };
    case actions.JOB_COURIER_LOCATION:
      return {
        ...state,
        jobLocationData: action.payload,
      };
    case actions.RATE_COURIER_FEEDBACK:
      return {
        ...state,
        rateCourierData: action.payload,
      };
    case actions.SHOW_TIMER_FROM_PUBNUB:
      return {
        ...state,
        showTimerfromPubnub: action.payload,
      };
    case actions.SHOW_FEEDBACK_FROM_PUBNUB:
      return {
        ...state,
        showFeedBackfromPubnub: action.payload,
      };
    case actions.SHOW_LOADER_FROM_PUBNUB:
      return {
        ...state,
        showLoaderfromPubnub: action.payload,
      };
    case actions.CLEARJOB:
      return {
        ...state,
        status: false,
        multiJobId: null,
        isCouriersList: false,
        error: false,
        loading: false,
        message: false,
        isParcelAdded: false,
        isItemAdded: false,
        isParcelDelete: false,
        isParcelUpdate: false,
        isNextDaySevices: false,
        isItemDelete: false,
        isItemUpdate: false,
        editAddressData: {},
        editParcelData: {},
        parcels: [],
        jobDetails: {},
        quotaDetails: {},
        nextDayEstimate: {},
        parcelforEdit: {},
        nextDayQuote: {},
        parcelStores: {},
        currentJobs: [],
        multiCurrentAddress: null,
        filteredData: [],
        parcelAllAddress: [],
        addSelectedJobIdParcel: '',
        selectedJobIdForParcel: [],
        CSVStatus: [],
        lastParcelAddress: [],
        isClicked: false,
        publicCouriersNearMe: [],
        privateCouriersNearMe: [],
        optimisedSequence: [],
        updatedCourier: false,
        sameDayJobDetails: {},
      };
    case actions.GET_SAME_DAY_LABELS_INITIATED:
      return {
        ...state,
        sameDayLabels: [],
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_SAME_DAY_LABELS_SUCCESS:
      return {
        ...state,
        sameDayLabels: action.payload,
        message: false,
        error: false,
        loading: false,
      };
    case actions.GET_SAME_DAY_LABELS_ERROR:
      return {
        ...state,
        sameDayLabels: [],
        loading: false,
        error: true,
        message: action.error,
      };
    case actions.GET_LABEL_ZIP_FILE_INITIATED:
      return {
        ...state,
        labelZipFile: [],
        message: false,
        error: false,
        loading: true,
      };
    case actions.GET_LABEL_ZIP_FILE_SUCCESS:
      return {
        ...state,
        labelZipFile: action.payload,
        message: false,
        error: false,
        loading: false,
      };
    case actions.GET_LABEL_ZIP_FILE_ERROR:
      return {
        ...state,
        labelZipFile: [],
        loading: false,
        error: true,
        message: action.error,
      };

    default:
      return state;
  }
};
