const JobBoardsConstant = {
  profile: '-',
  jobBoards: 'Job Boards',
  drafts: 'DRAFTS',
  scheduled: 'SCHEDULED',
  active: 'ACTIVE',
  completed: 'COMPLETED',
  left: 'LEFT',
  Carrier: 'CARRIER',
  currentPrice: 'CURRENT PRICE',
  deliceryAddress: 'DELIVERY ADDRESS',
  draftStatus: 'DRAFT STATUS',
  vehicle: 'VEHICLE',
  icons: 'ICONS',
  inProgress: 'In Progress -',
  percent: '%',
  pound: '£',
  CourierInfo: 'COURIER INFO',
  dateAndLocation: 'DATE AND LOCATION',
  location: 'LOCATION',
  jobProgress: 'JOB PROGRESS',
  parcel: 'PARCELS',
  parcel_sm: 'parcel',
  recipt: 'RECEIPT',
  Quote: 'QUOTE',
  details: 'DETAILS',
  allocation: 'ALLOCATION',
  listofCourires: 'LIST OF COURIERS',
  printYourBil: 'PRINT LABELS',
  completedBooking: 'COMPLETE',
  BookingText: 'BOOK',
  startDate: 'Start Date',
  startendTime: 'Start-Time  End-Time',
  riders: 'Riders',
  startAddress: 'Start Address',
  rebook: 'REBOOK',
  pickupDropoffDate: 'PICK UPDROP OFF DATE',
  pickDropDate: 'PICK UP/DROP OFF DATE',
  pickupDropoffValue: '13.03.2020 - 13.03.2020',
  TimeRanges: 'TIME RANGE',
  TimeRangesValue: '09:00 - 15:00',
  courierID: 'COURIER ID',
  driverID: 'DRIVER ID',
  courierDriver: 'COURIER DRIVER',
  orderID: 'ORDER ID',
  courierVan: 'COURIER VAN',
  addNote: 'ADD. NOTES',
  addNoteText: 'Please do bare in mind my bag is very..',
  additionalContectInfo: 'ADDITIONAL CONTACT INFO',
  exprctedPickupName: 'EXPECTED PICK UP NAME',
  exprctedDropoffName: 'EXPECTED DROP OFF NAME',
  finalOrderStatus: 'FINAL ORDER STATUS',
  reciverName: 'RECEIVER NAME',
  finalStatus: 'FINAL STATUS',
  one: '01',
  two: '02',
  exprctedPickupWindow: 'EXPECTED PICKUP WINDOW TIME',
  from: 'FROM',
  to: 'To',
  arivalTime: 'ARRIVAL TIME',
  waitingTime: 'WAITING TIME',
  waitingReason: 'WAITING REASON',
  exprctedDeliveryWindow: 'EXPECTED DELIVERY WINDOW TIME',
  pickUpTime: 'PICKUP TIME',
  countBefore: ' (',
  countafter: ')',
  UK: 'United Kingdom',
  yourReceipt: 'YOUR QUOTE',
  courierGov: 'VAT',
  totalPricetxt: 'TOTAL PRICE',
  pricesChange: 'These prices are a subject to',
  dailyChange: 'daily change.',
  routePreview: 'Route Preview',
  pickup_address: 'Brick St 157, Toddington, UK, DG8 3XJ',
  delivery_address: '81 Front Street, Knockville, UK, IV1 1SH',
  zero: '0.0',
  parcels2: 'Parcels 200lb',
  parcels4: 'Parcels 400lb',
  courierGovPrice: 10,
  parcels2Price: 70,
  parcels4Price: 140,
  price: 'PRICE',
  pickUpDropoff: 'PICK UP/DROP OFF',
  deliveryStates: 'DELIVERY STATUS',
  cost: 'COST',
  status: 'STATUS',
  refID: 'REF. ID',
  schedualStatus: 'SCHEDULE STATUS',
  draftReport: 'DRAFT REPORT',
  progressReport: 'PROGRESS REPORT',
  timeRange: 'TIME RANGE',
  houseDelivery: '',
  size: 'Size',
  weight: 'Weight',
  referenceNumber: 'Reference number',
  packageValue: 'Package value',
  labelUrl: 'Label URL',
  parcelStatus: 'Parcel status',
  postDrop: 'Post Office Drop Off',
  directDrop: 'Direct Pick Up - Drop Off',
  estArrival: ' EST. TIME OF ARRIVAL',
  warning: 'Warning: You haven’t completed this order',
  currentQuote: 'CURRENT QUOTE',
  yourCurrentQuote: 'YOUR CURRENT QUOTE',
  pickupAddress: 'Pickup address',
  pickupStreet: 'STREET, NUMBER',
  pickupFloor: 'BUILDING FLOOR,FLAT',
  pickupPostCode: 'POSTCODE',
  pickupCity: 'CITY',
  pickupCountryCode: 'COUNTRY CODE',
  pickupTipsHowToFind: 'TIPS HOW TO FIND',
  pickupPersonName: 'RESPONSIBLE',
  pickupEmail: 'EMAIL',
  pickupMobileNumber: 'MOBILE NUMBER',
  pickupPhoneNumber: 'PHONE NUMBER',
  pickupCompanyName: 'COMPANY NAME',
  deliveryAddress: 'Delivery address',
  deliveryStreet: 'STREET, NUMBER',
  deliveryFloor: 'BUILDING FLOOR,FLAT',
  deliveryPostCode: 'POSTCODE',
  deliveryCity: 'CITY',
  deliveryCountryCode: 'COUNTRY CODE',
  deliveryTipsHowToFind: 'TIPS HOW TO FIND',
  deliveryPersonName: 'RESPONSIBLE',
  deliveryEmail: 'EMAIL',
  deliveryMobileNumber: 'MOBILE NUMBER',
  deliveryPhoneNumber: 'PHONE NUMBER',
  deliveryCompanyName: 'COMPANY NAME',
  map: 'MAP'
};
const settingParcel = ['Edit Parcel', 'Remove Parcel'];
const settingItem = ['Edit Item', 'Remove Item'];

const flagsList = [
  {
    label: 'Fragile',
    value: 'is_fragile'
  },
  {
    label: 'Glass',
    value: 'is_glass'
  },
  {
    label: 'Medical',
    value: 'is_medical'
  },
  {
    label: 'Flowers',
    value: 'is_flower'
  },
  {
    label: 'Do not rotate',
    value: 'is_not_rotatable'
  },
  {
    label: 'Hot Food',
    value: 'is_food'
  },
  {
    label: 'Liquid Item',
    value: 'is_liquid'
  },
  {
    label: 'Alcohol',
    value: 'is_alcohol'
  },
  {
    label: 'Pork',
    value: 'is_pork'
  },
  {
    label: 'Beef',
    value: 'is_beef'
  },
  {
    label: 'Baked Goods',
    value: 'is_baked'
  }
];
const JobDays = ['NEXT DAY', 'SAME DAY ', 'SHIFTS'];
export { JobBoardsConstant, settingParcel, settingItem, flagsList, JobDays };
