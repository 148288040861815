import isEmpty from 'lodash.isempty';
import { configVar } from 'modules/config';
import { MultiJobsType } from 'App/AppConstant';
export const getCookie = name => {
  let match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
  if (match) return match[2];
  return false;
};
export const cookieName = cookie_name => {
  let name;
  switch (configVar.ENVIRONMENT) {
    case 'development':
      name = String('test_' + cookie_name);
      break;
    default:
      name = String(cookie_name);
      break;
  }
  return name;
};
export const getAuthToken = () => {
  if (localStorage.auth) {
    let auth = JSON.parse(localStorage.auth);
    if (auth && auth.session_id) return auth.session_id;
    return false;
  }
  return false;
};

export const checkKeys = (data, key) => {
  if (!isEmpty(data) && !data[key]) {
    return true;
  }
  return false;
};

export const filterJobs = currentJobs => {
  let singleJobs = [],
    multiJobs = [],
    subJobs = [],
    isTeam = false;
  if (!isEmpty(currentJobs) && Array.isArray(currentJobs)) {
    currentJobs.map(currJob => {
      if (currJob.status !== 'NEW' && currJob.team_id) {
        isTeam = true;
      }
      if (
        currJob.multi_job_type === MultiJobsType.MULTI_PICKUP ||
        currJob.multi_job_type === MultiJobsType.MULTI_DROP
      ) {
        multiJobs.push(currJob);
      } else if (
        currJob.multi_job_type === MultiJobsType.SUBJOB_MULTI_PICKUP ||
        currJob.multi_job_type === MultiJobsType.SUBJOB_MULTI_DROP
      ) {
        subJobs.push(currJob);
      } else {
        singleJobs.push(currJob);
      }
    });
    if (!isEmpty(subJobs)) {
      let subJobSelect = {};
      let filterdMultiJobs = multiJobs.map(parentJob => {
        let subFileredJobs = subJobs.filter(ele => ele.multi_job_id === parentJob.id);
        if (!isEmpty(subFileredJobs)) {
          subJobSelect = {
            ...subJobSelect,
            [`${parentJob.id}`]: subFileredJobs[0]
          };
          return {
            ...parentJob,
            sub_jobs: [...subFileredJobs]
          };
        }
      });
      return {
        jobs: [...filterdMultiJobs, ...singleJobs],
        selectSubJob: subJobSelect,
        isTeam
      };
    } else {
      return {
        jobs: [...multiJobs, ...singleJobs],
        selectSubJob: {},
        isTeam
      };
    }
  }
  return {
    jobs: [],
    selectSubJob: {},
    isTeam
  };
};
