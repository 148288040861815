import * as Yup from 'yup';

const FormValidation = {
  firstNameRequired: 'Please enter a firstname',
  lastNameRequired: 'Please enter a lastname',
  // personNameRequired: 'Please enter a name',
  personNameRequired: 'Please enter your first and last name',
  emailRequired: 'Email address is required',
  emailInvalid: 'Invalid email address',
  passwordRequired: 'Password is required',
  passwordMin: 'Please enter more than 6 characters',
  confrimPasswordRequired: 'Please enter confirm password',
  confrimPasswordMatch: 'Passwords must match',
  countryRequired: 'Please enter your country',
  cityRequired: 'Please enter your city name',
  chooseCityRequired: 'Please choose your city',
  addressRequired: 'Please enter your address',
  countryCodeRequired: 'Please choose your country code',
  phoneNumberRequired: 'Please enter your phone number',
  phoneNumberMinimum: 'Please enter your valid phone number',
  mobileNumberRequired: 'Please enter your mobile number',
  mobileNumberMinimum: 'Please enter your valid mobile number',
  locationRequired: 'Please enter a your location',
  codeRequired: 'Please enter a your code',
  streetNumberRequired: 'Please enter your street name and number',
  passcodeRequired: 'Please enter your postcode',
  buildingRequired: 'Please enter your building name, floor, flat',
  agreeTermRequires: 'Must accept terms and conditions',
  agreePolicyRequires: 'Please check the agreement',
  pickupAddressRequired: 'Please enter the pickup Address',
  deliveryAddressRequired: 'Please enter the delivery Address',
  weightRequired: 'Please enter the weight',
  dateRequired: 'Please enter the date',
  companyNameRequired: 'Please enter a company name',
  numberUkValidate: 'Please enter valid UK number',
  teamNameRequired: 'Please enter a team name',
  termsConditionRequired: 'Please accept terms & condition',
  privacyPolicyRequired: 'Please accept privacy policy',
};
const AddressConstant = {
  addressTitlePickup: 'Pickup address',
  addressTitleDelivery: 'Delivery address',
  addressLookup: 'Search for address',
  streetNumber: 'Street, number',
  buildingFloor: 'Building, floor, flat',
  city: 'City',
  contactTitle: 'Contact details',
  deliveryOptionTitle: 'Delivery options',
  postcode: 'Postcode',
  country: 'Country',
  personName: 'Full name',
  firstName: 'First name',
  lastName: 'Last name',
  email: 'E-mail',
  companyName: 'Company name',
  mobileNumber: 'Mobile number',
  phoneNumber: 'Secondary number',
  w3w: 'What 3 words location',
  tips: 'Please specify any additional notes',
  additionalNotes: 'Additional notes',
  deliverytips: 'Please specify any additional notes',
  registrationNumber: 'Company registration number',
  vatNumber: 'Company vat number',
  billingAddress: 'BILLING ADDRESS',
  reference: 'Refrence',
  financeName: 'Finance person name',
  financeEmail: 'Finance email',
  financePhoneNumber: 'Finance phone number',
  sameDayDelivery: 'Here you input full',
  providesYou: ' details for your',
  sameDayDeliveryhours: 'consignment, including parcel or item ',
  hours: 'specifics including, weight, volume ',
  googleSearch: 'ADDRESS LOOKUP',
  nextDaydelivery: '',
  providesYouWith: 'and ',
  withDelivery: 'your unique refrence number/s if required.',
  customAddress: 'ADDRESS DETAILS',
  yourLocation: 'YOUR LOCATION WILL BE AUTOMATICALLY UPLOADED TO FAVOURITES',
  contactAddress: 'Add pickup address & contact details',
  shiftAddress: 'Add starting address & contact details',
  deliveryAddress: 'Add delivery address & contact details',
  assignParcelTO: 'ASSIGN PARCEL TO',
  parcelDescription: 'Parcel description',
  addToFavourite: 'Add to Favourite',
  orderPreview: 'Order Preview',
  address: 'ADDRESS',
  pickUpDate: 'PICK UP DATE',
  DropOffDate: 'DROP OFF DATE',
  pickUpDropoff: 'PICK UP AND DROP OFF',
  pickUp: 'PICK UP',
  dropoff: 'DROP OFF',
  pound: '£',
  courierGov: 'VAT',
  to: 'To',
  yourrecept: 'YOUR QUOTE',
  orderReceipt: 'Order Receipt',
  priceSubject: 'These prices are a subject to',
  dailyChange: 'daily change.',
  parcels2: 'Parcels 200lb',
  parcels4: 'Parcels 400lb',
  cancel: 'CANCEL',
  orderQuote: 'Order Quote',
  edit: 'Edit',
  delete: 'Delete',
  location: 'LOCATION',
  sorry: 'Sorry!',
  nojob: 'No Jobs Added At This Moment',
  addaJob: 'ADD A JOB',
  totalPrice: 'TOTAL PRICE',
  UK: 'UK',
};

const TabConstant = {
  map: 'MAP',
};

const GlobalConstant = {
  empty: '-',
  what3words: 'WHAT 3 WORDS',
};
const MenuLink = [
  {
    name: 'Booking',
    path: '/booking',
  },
  {
    name: 'Manage Bookings',
    path: '/job-boards',
  },
  {
    name: 'Favourites',
    path: '/favourites',
  },
  {
    name: 'Payments',
    path: '/payments',
  },
  {
    name: 'History',
    path: '/history',
  },
  // {
  //   name: 'Profile',
  //   path: '/profile'
  // },
  // {
  //   name: 'Teams',
  //   path: '/teams',
  // },
];
const PlacesOptions = {
  componentRestrictions: {
    country: ['UK', 'GB'],
  },
};
const Months = {
  '01': 'January',
  '02': 'February',
  '03': 'March',
  '04': 'April',
  '05': 'May',
  '06': 'June',
  '07': 'July',
  '08': 'August',
  '09': 'September',
  10: 'October',
  11: 'November',
  12: 'December',
};

const VehicleMap = {
  10: 'Push Bike',
  15: 'Cargo Bike',
  20: 'Motor Bike',
  30: 'Car',
  40: 'Small Van',
  50: 'Large Van',
};
const AppValidation = {
  ukNumber: /^(0044|0|\+?44)[12378]\d{8,9}$/,
};

const HandleAddressTypes = [
  'address1',
  'address2',
  'city',
  'postcode',
  'country_code',
  'location_lat',
  'location_lng',
  'person_name',
  'company_name',
  'mobile_number',
  'phone_number',
  'email',
  'w3w',
  'tips_how_to_find',
];

const MultiJobsType = {
  STANDARD: '0',
  SUBJOB_MULTI_PICKUP: '11',
  SUBJOB_MULTI_DROP: '12',
  MULTI_PICKUP: '111',
  MULTI_DROP: '112',
  MULTI_UNDEFINED: undefined,
  MULTI_NULL: null,
};

const isMasterJob = (job) => {
  return (
    job.multi_job_type === MultiJobsType.MULTI_DROP ||
    job.multi_job_type === MultiJobsType.MULTI_PICKUP ||
    job.multi_job_type === MultiJobsType.MULTI_UNDEFINED
  );
};

const DefaultCoordinate = {
  lat: 51.50085,
  lng: -0.121814,
};

const AddressPayload = {
  address1: '',
  address2: '',
  city: '',
  country_code: 'GB',
  postcode: '',
  tips_how_to_find: '',
  w3w: '',
};

const UserPayload = {
  company_name: '',
  email: '',
  mobile_number: '',
  person_name: '',
  phone_number: '',
};

const AddressFields = ['address1', 'address2', 'postcode', 'city', 'country_code', 'tips_how_to_find', 'w3w'];
const AddressRequiredFields = ['address1', 'postcode', 'city', 'mobile_number', 'person_name'];

const GoogleComponentTypes = [
  { type: 'route', label: 'route' },
  { type: 'street_number', label: 'street_number' },
  { type: 'postal_code', label: 'postcode' },
  { type: 'postal_town', label: 'city' },
  { type: 'country', label: 'country_code' },
];

const AddressSchema = Yup.object().shape({
  email: Yup.string().email(FormValidation.emailInvalid),
  person_name: Yup.string().required(FormValidation.personNameRequired),
  address1: Yup.string().required(FormValidation.streetNumberRequired),
  postcode: Yup.string().required(FormValidation.passcodeRequired),
  phone_number: Yup.string()
    .test({
      message: FormValidation.phoneNumberMinimum,
      test: (value) => value == null || (value.charAt(0) === '1' ? value.length >= 9 : value.length === 10),
    })
    .test({
      message: FormValidation.numberUkValidate,
      test: (value) => {
        if (value) {
          value = '0' + value;
          if (AppValidation.ukNumber.test(value)) {
            return true;
          }
          return false;
        }
        return true;
      },
    })
    .nullable(),
  mobile_number: Yup.string()
    .required(FormValidation.mobileNumberRequired)
    .test({
      message: FormValidation.mobileNumberMinimum,
      test: (value) => value == null || (value.charAt(0) === '1' ? value.length >= 9 : value.length === 10),
    })
    .test({
      message: FormValidation.numberUkValidate,
      test: (value) => {
        value = '0' + value;
        if (AppValidation.ukNumber.test(value)) {
          return true;
        }
        return false;
      },
    }),
  city: Yup.string().required(FormValidation.cityRequired),
  country_code: Yup.string().required(FormValidation.countryRequired),
});

const handleNumberBlur = (setFieldValue, event) => {
  let value = event.target.value;
  let name = event.target.name;

  let number;
  setFieldValue(name, value);
  if (value.length >= 9 && value.charAt(0) === '0') {
    number = value.slice(1);
    setFieldValue(name, number);
  }
  if (number) {
    if (number.charAt(0) === '1') {
      setFieldValue(name, number);
    }
  }
};

const scrollIntoItem = (selector) => {
  document.querySelector(selector).scrollIntoView({ behavior: 'smooth', block: 'center' });
};

const removeBodyHeight = () => {
  document.body.classList.add('body-remove-height');
};

const hideOverflow = () => {
  document.body.classList.add('body-hide-overflow');
};

const hideIntercom = () => {
  const exist = document.getElementById('intercom-container');
  if (exist) document.getElementById('intercom-container').className += ' hide';
};
const showIntercom = () => {
  const exist = document.getElementById('intercom-container');
  if (exist) exist.classList.remove('hide');
};
const removeOverflow = () => {
  document.body.classList.remove('body-hide-overflow');
};

const addBodyHeight = () => {
  document.body.classList.remove('body-remove-height');
};

const handlew3wBlur = (setFieldValue, event) => {
  let value = event.target.value;
  let name = event.target.name;

  let number;
  setFieldValue(name, value);
  if (value.startsWith('///')) {
    number = value.slice(3);
    setFieldValue(name, number);
  }
};

const CONFIRMED_BY_CUSTOMER = 'CONFIRMED_BY_CUSTOMER';

const jobTrackerStatusList = [
  "ACCEPTED_BY_COURIER",
  "AT_PICKUP",
  "EN_ROUTE",
  "AT_DELIVERY",
  "TAKING_SIGNATURE",
  "EN_BACK_ROUTE",
  "CANCELED",
  "RETURNED",
  "STOLEN",
  "DAMAGED",
  "DELIVERED"
]
export {
  scrollIntoItem,
  removeBodyHeight,
  CONFIRMED_BY_CUSTOMER,
  hideOverflow,
  removeOverflow,
  addBodyHeight,
  isMasterJob,
  handleNumberBlur,
  showIntercom,
  hideIntercom,
  TabConstant,
  UserPayload,
  AddressPayload,
  AppValidation,
  DefaultCoordinate,
  HandleAddressTypes,
  VehicleMap,
  FormValidation,
  AddressConstant,
  AddressRequiredFields,
  MenuLink,
  Months,
  GlobalConstant,
  PlacesOptions,
  MultiJobsType,
  AddressFields,
  AddressSchema,
  handlew3wBlur,
  GoogleComponentTypes,
  jobTrackerStatusList,
};

