import { Modal, notification } from 'antd';
import { push } from 'connected-react-router';
import isEmpty from 'lodash.isempty';
import { axiosAuthPost, axiosPost, backendSignin } from 'modules/Axios';
import { apiConstant } from 'modules/config';
import { onError } from 'modules/errorHandler';
import { loader } from 'redux/app/actions';
import { getFavourites } from 'redux/favourite/actions';
import {
  getUserData,
  getUserSecondaryData,
  profileClear,
  userDataCheck
} from 'redux/profileUpdate/actions';
import * as actions from './constant';



// ANCHOR - Login Action
export const login = payload => async dispatch => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.LOGIN_INITIATED });
    let response = await axiosPost(apiConstant.AUTH_LOGIN, payload);
    if (!response.success) {
      dispatch({
        type: actions.LOGIN_ERROR,
        error: response.error.message
      });
    } else if (response.data.user_type === "1") {
      response.error = {
        code: "ERROR_INVALID_PASSWORD",
        message: "Authentication Failed"
      }
      dispatch({
        type: actions.LOGIN_ERROR,
        error: response.error.message
      });
      Modal.error({
        title: 'You are trying to login with a courier account!',
        content: "Our Booking platform is for customers only. If you think there is a problem with your account please contact our support through our chat feature",
        className: 'modal-custom-error',
        width: "50%",
        centered: true,
        onOk() {
        }
      });
    } else {
      localStorage.setItem('auth', JSON.stringify(response.data));
      await dispatch({
        type: actions.LOGIN_SUCCESS,
        payload: response
      });
      await dispatch(getUserData());
      await dispatch(getUserSecondaryData());
      await dispatch(getFavourites());
      notification['success']({
        message: 'Authentication Success'
      });
    }
  } catch (error) {
    console.log(error, 'action catch');
    dispatch({
      type: actions.LOGIN_ERROR,
      error
    });
  } finally {
    dispatch(loader(false));
  }
};

// ANCHOR - Social Signin Redirection Action
export const socialSignin = payload => dispatch => {
  try {
    backendSignin(payload);
  } catch (error) {
    onError(error, 'socialsignin', 'Redirect error');
  }
};

// ANCHOR - Authentication Setter Action
export const setAuth = payload => dispatch => {
  dispatch({
    type: actions.SET_AUTH,
    payload
  });
};

// ANCHOR - Logout Action
export const logout = () => async dispatch => {
  try {
    dispatch({ type: actions.LOGOUT_INITIATED });
    dispatch(loader(true));
    await axiosPost(apiConstant.AUTH_LOGOUT, {
      session_id: JSON.parse(localStorage.auth).session_id
    });
    if (!isEmpty(window) && !isEmpty(window.Intercom)) {
      window.Intercom('shutdown');
    }
    localStorage.clear();
    sessionStorage.clear();
    await dispatch(profileClear());
    dispatch(loader(false));
    dispatch({
      type: actions.LOGOUT
    });
    dispatch(push('/'));
  } catch (error) {
    dispatch(loader(false));
    console.log(error, 'Logout Error');
  }
};

// ANCHOR - Social Signin Action
export const socialLogin = payload => async dispatch => {
  if (payload.success) {
    localStorage.setItem('auth', JSON.stringify(payload.data));
    if (payload.data.newly_registered) {
      console.log('new user');
      dispatch({
        type: actions.SOCIAL_LOGIN_NEW
      });
      await dispatch(userDataCheck());
      await dispatch(getFavourites());
      dispatch(push('/register-profile'));
    }
    await dispatch(userDataCheck());
    await dispatch(getFavourites());
    dispatch({
      type: actions.SOCIAL_LOGIN,
      payload
    });
  } else {
    dispatch({
      type: actions.SOCIAL_LOGIN_ERROR
    });
  }
};

// ANCHOR - Authentication Check Action
export const checkSession = () => async dispatch => {
  try {
    let response = await axiosAuthPost(apiConstant.GET_SESSION, {});
    if (response.success) {
      let responseData = response.data;
      if (!responseData.authorized) {
        localStorage.removeItem('auth');
        sessionStorage.removeItem('userData');
        dispatch(push('/'));
      } else {
        await dispatch(userDataCheck());
        await dispatch(getFavourites());
        await dispatch({
          type: actions.SET_AUTH,
          payload: responseData.session_id
        });
      }
    }
  } catch (error) {
    onError(error, 'CheckSession', 'Action error call');
  }
};
