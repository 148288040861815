import { Radio, Tooltip } from 'antd';
import { CardHeader } from 'components/CommonStyle/style';
import React, { Component } from 'react';
import { RadioWrapper } from './style';

// const disabledValue = ['Same Day', 'International', 'Wait and return'];
const disabledValue = ['International', 'Wait and return'];
let hideValues = [];
if (process.env.REACT_APP_ENABLE_SHIFT !== 'true') {
  hideValues.push('Shift');
}
if (process.env.REACT_APP_ENABLE_SAMEDAY !== 'true') {
  hideValues.push('Same Day');
}
class RadioButtonGroup extends Component {
  /* Will be removed */
  multiPickup = 'Multiple Pick Up';
  getRadioList = () => {
    const { data, disableRadios, style, showTooltip } = this.props;
    return data.map((element, i) => {
      if (disabledValue.includes(element) || disableRadios.includes(element)) {
        return (
          <Radio value={i + 1} disabled={true} key={i} className={hideValues.includes(element) ? 'not-shown' : ''}>
            {element}
          </Radio>
        );
      } else {
        return (
          <>
            {/* {showTooltip ? (
              <div id="header" className="tooltip-radio-wrapper">
                <Radio
                  value={i + 1}
                  key={'custom'}
                  className={hideValues.includes(element) ? 'not-shown' : ''}
                  style={style}
                >
                  {element}
                </Radio>
                <div className="type-info">
                  {element === 'Flexible' ? (
                    <span>
                      Optimize route to find the most cost-effective route. Instead of driving to
                      delivery locations based on the order they came in, you can optimize routes in
                      a matter of seconds to reduce mileage, increase efficiency, and courier will
                      spend less time driving and more time doing.
                    </span>
                  ) : (
                    <span>
                      Courier will deliver to locations based on the order they were added without
                      optimizing route
                    </span>
                  )}
                </div>
              </div>
            ) : ( */}
            <Radio value={i + 1} key={i} className={hideValues.includes(element) ? 'not-shown' : ''} style={style}>
              {element}
              {showTooltip ? (
                <Tooltip
                  placement="top"
                  title={
                    element === 'Flexible sequence' ? (
                      <CardHeader>
                        <div className="custom-tooltip-inner">
                          Optimize route to find the most cost-effective route. Instead of driving to delivery locations based on the order
                          they came in, you can optimize routes in a matter of seconds to reduce mileage, increase efficiency, and courier
                          will spend less time driving and more time doing.
                        </div>
                      </CardHeader>
                    ) : (
                      <CardHeader>
                        <div className="custom-tooltip-inner">
                          Optimise your route to get a cheaper rate on your order. This will also improve this route's efficiency.
                        </div>
                      </CardHeader>
                    )
                  }
                >
                  <i className="info-icon service-tips" />
                </Tooltip>
              ) : (
                ''
              )}
            </Radio>
            {/* )} */}
          </>
        );
      }
    });
  };
  handleChange = e => {
    this.props.handleType(e.target.value);
  };
  render() {
    const { data, name, value, type, className } = this.props;

    return (
      <RadioWrapper>
        <Radio.Group name={name} defaultValue={2} value={value} onChange={this.handleChange} className={className ? className : ''}>
          {this.getRadioList()}
        </Radio.Group>
      </RadioWrapper>
    );
  }
}
RadioButtonGroup.defaultProps = {
  data: [],
  disableRadios: [],
  name: '',
  showTooltip: false
};
export default RadioButtonGroup;
