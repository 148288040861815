import { Col, DatePicker, Row } from 'antd';
import isEmpty from 'lodash.isempty';
import moment from 'moment';
import Slider, { createSliderWithTooltip } from 'rc-slider';
import 'rc-slider/assets/index.css';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { endTimeOpen, startDate, startTime, startTimeOpen } from 'redux/shift/actions';
import { FormWrapper } from './style';
const Range = createSliderWithTooltip(Slider.Range);
const TimeFormat = 'HH:mm';
const DateFormat = 'YYYY-MM-DD';
moment.locale('en', {
  week: {
    dow: 1
  }
});
moment.locale('en');
moment().weekday(1);
class CustomDateTimePicker extends Component {
  state = {
    selectedDate: '',
    currentDate: '',
    time: []
  };
  handleChange = (ele, dateString) => {
    const { name, onDateTimeChange, timeName, workingHours } = this.props;
    onDateTimeChange(name, dateString);
    // this.props.dateTimeTouched();
    if (timeName == 'startTime') {
      this.props.startDate({ startDate: dateString });
    }
  };
  handleDisabledDate = currentDate => {
    if (moment().format('HH') >= 18) {
      return currentDate < moment().subtract('0', 'days');
    }
    return currentDate < moment().subtract('1', 'days');
  };

  onSliderAfterChange = async () => {
    let timeStart = this.props.time[0];
    let timeEnd = this.props.time[1];
    if (timeEnd - timeStart > 46800) {
      timeEnd = timeStart + 46800;
    }
    timeStart = moment.unix(timeStart).format(TimeFormat);
    timeEnd = moment.unix(timeEnd).format(TimeFormat);
    this.props.onDateTimeChange('time', [timeStart, timeEnd]);
  };
  onSliderChange = value => {
    this.props.onDateTimeChange('time', [moment.unix(value[0]).format(TimeFormat), moment.unix(value[1]).format(TimeFormat)]);
  };
  render() {
    const { placeholder, className, timeValue, timeName, value, errorsDateTime, name, startTimeValue, endTimeValue } = this.props;
    const initDateVal = value !== '' ? moment(value).format(DateFormat) : '';
    let initDate =
      moment(initDateVal).format('HH') >= 18 && moment(initDateVal).format(DateFormat) == moment(new Date()).format(DateFormat)
        ? moment(initDateVal).add(1, 'days')
        : moment(initDateVal, DateFormat);
    return (
      <FormWrapper className={className}>
        <Row className="dt-tm-row">
          <Col lg={6} className="first-col">
            <DatePicker
              name={name}
              format={DateFormat}
              value={initDate}
              placeholder={placeholder}
              className="custom-datepicker"
              dropdownClassName="custom-datepicker-dropdown"
              onChange={this.handleChange}
              getCalendarContainer={node => node}
              allowClear={false}
              disabledDate={d => this.handleDisabledDate(d)}
              onOpenChange={() => this.handleDateOpen}
            />
            {!isEmpty(errorsDateTime) && errorsDateTime[name] && <div className="form-error">{errorsDateTime[timeName]}</div>}
          </Col>
          <Col lg={18}>
            <Range
              value={this.props.time}
              className={`mainWindowSlider`}
              min={this.props.timemin}
              max={this.props.timemax}
              onChange={this.onSliderChange}
              tipProps={{ visible: true }}
              tipFormatter={value => {
                const unixCode = value;
                const date = new Date(unixCode * 1000);
                return <span className={`tooltip purple`}>{`${moment(date).format('HH:mm')}`}</span>;
              }}
              pushable={10800}
              step={1800}
              railStyle={{
                height: 2,
                backgroundColor: '#5a5a5a'
              }}
              onAfterChange={this.onSliderAfterChange}
              trackStyle={{
                background: 'none'
              }}
            />
          </Col>
        </Row>
      </FormWrapper>
    );
  }
}
CustomDateTimePicker.defaultProps = {};
const mapStateToProps = state => ({
  startT: state.shift.startTime.startTime,
  shiftDetails: state.shift.shiftDetails,
  startD: state.shift.startDate.startDate,
  startTimeProps: state.shift.startTimeOpen,
  workingHours: state.booking.workingHours,
  endTimeProps: state.shift.endTimeOpen
});

const mapStateToDispatch = dispatch => ({
  startTime: payload => dispatch(startTime(payload)),
  startDate: payload => dispatch(startDate(payload)),
  startTimeOpen: payload => dispatch(startTimeOpen(payload)),
  endTimeOpen: payload => dispatch(endTimeOpen(payload))
});

export default connect(mapStateToProps, mapStateToDispatch)(CustomDateTimePicker);
