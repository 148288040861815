import { size } from 'App/device';
import { borderRadius } from 'App/mixins';
import styled from 'styled-components';

const FormWrapper = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
  background: #fff;
  ${borderRadius('0')};

  & > div:first-child {
    /* margin-right: 12px; */
    flex: 3;
  }
  & > div:last-child {
    flex: 1;
  }
  // box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);
  .ant-calendar-picker-container {
    z-index: 1000;
    top: 46px;
  }
  .custom-datepicker {
    border-bottom: 1px solid #9d9d9d;

    & .ant-calendar-picker-icon {
      right: unset;
      position: absolute;
      z-index: 0;
      top: 44%;
      svg {
        color: #828097;
        width: 20px;
        height: 20px;
      }
    }
    &.ant-calendar-picker {
      & .ant-calendar-picker-input {
        padding-left: 30px !important;
        background: none !important;
        box-shadow: none !important;
        padding-right: 0 !important;
        position: relative;
        display: block;
        width: 100%;
        line-height: 1.5;
        margin: 0;
        -webkit-appearance: none;
        background: #fff;
        border: 0;
        border-radius: 5px;
        -webkit-border-radius: 5px;
        height: 58px;
        font-size: 15px;
        font-weight: 400;
        padding: 10px 30px;
        font-family: ${props => props.theme.secondaryFont}, sans-serif;
        box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);
        transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
        color: #828097;
        &::-webkit-input-placeholder {
          color: #828097;
          opacity: 1;
        }
        &:-moz-placeholder {
          color: #828097;
          opacity: 1;
        }
        &::-moz-placeholder {
          color: #828097;
          opacity: 1;
        }
        &:-ms-input-placeholder {
          color: #828097;
          opacity: 1;
        }
        :hover,
        :focus {
          outline: none;
          box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.1);
        }
        @media ${size['desktop-sm-max']} {
          font-size: 16px;
          height: 44px;
          padding: 10px 20px;
        }
        @media ${size['tablet-max']} {
          font-size: 14px;
        }
      }
    }
  }
  .time-value-btn {
    border-radius: 4px;
    font-size: 15px;
    height: 46px;
    line-height: 46px;
    color: #323232;
    font-weight: 400;
    box-shadow: none;
    margin-left: 10px;
    text-align: left;
    font-family: 'OpenSans';
    background: #fff !important;
    border: 1px solid #9d9d9d;
    &.grey-text {
      color: #979797;
    }
  }
  .custom-datepicker-dropdown {
    & .ant-calendar {
      background: #fff;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
      border-radius: 5px;
      -webkit-border-radius: 5px;
      -moz-border-radius: 5px;
      padding: 30px;
      border: 0;
      font-family: ${props => props.theme.secondaryFont}, sans-serif;
      color: #49465d;
      // text-align: center;
      // font-size: 18px;
      // font-weight: 500;
      font-size: 15px;
      width: auto;
      & .ant-calendar-header {
        border-bottom: 0px;
        height: 30px;
        & a.ant-calendar-prev-year-btn,
        a.ant-calendar-next-year-btn {
          display: none;
        }
        & .ant-calendar-prev-month-btn::before,
        .ant-calendar-prev-month-btn::after,
        .ant-calendar-next-month-btn::before,
        .ant-calendar-next-month-btn::after {
          width: 12px;
          height: 12px;
        }
        & .ant-calendar-prev-month-btn:hover::before,
        .ant-calendar-prev-month-btn:hover::after,
        .ant-calendar-next-month-btn:hover::before,
        .ant-calendar-next-month-btn:hover::after {
          border-color: white;
        }
        & .ant-calendar-prev-month-btn::before:hover {
          color: rgb(255, 255, 255);
        }
        & .ant-calendar-my-select {
          & .ant-calendar-month-select,
          .ant-calendar-year-select {
            //month select
          }
        }
      }
      & .ant-calendar-body {
        & .ant-calendar-column-header {
          & .ant-calendar-column-header-inner {
            font-weight: 500;
          }
        }
        & .ant-calendar-tbody {
          & .ant-calendar-date {
            width: 35px;
            height: 35px;
            margin: 0 auto;
            padding: 8px;
            color: rgba(0, 0, 0, 0.65);
            line-height: normal;
            text-align: center;
            background: transparent;
            border: 1px solid transparent;
            border-radius: 4px;
            -webkit-transition: background 0.3s ease;
            transition: background 0.3s ease;
            display: table-cell;
            position: relative;
            border: none;
          }
          & .ant-calendar-last-month-cell {
            & .ant-calendar-date {
              color: #828097;
              opacity: 0.5;
              // color: #777777;
              // font-weight: normal;
              // font-size: 15px;
            }
          }
          & .ant-calendar-cell {
            padding: 0px;
            font-weight: 500;
            border-radius: 4px;
            &.ant-calendar-disabled-cell {
              color: #828097;
              opacity: 0.5;
            }
          }
          & .ant-calendar-cell:not(.ant-calendar-last-month-cell):not(.ant-calendar-next-month-btn-day) {
            & .ant-calendar-date {
              color: #777777;
              font-weight: normal;
              font-size: 15px;
            }
          }
          & .ant-calendar-cell:hover {
            background: #ffa100;
            & .ant-calendar-date {
              color: #fff !important;
            }
            & .ant-calendar-date:hover {
              box-shadow: none;
            }
          }
          & .ant-calendar-selected-day {
            background: #ffa100;
            z-index: 10;
            position: relative;

            & .ant-calendar-date {
              background: #ffa100;
              border-color: #ffa100;
              color: #fff !important;
              font-weight: bold !important;
              font-size: 15px !important;
              &:after {
                content: '';
                position: absolute;
                background: #e27c17;
                box-shadow: 0px 4px 4px rgba(226, 124, 23, 0.25);
                border-radius: 4px;
                width: 35px;
                height: 35px;
                left: 3px;
                top: 3px;
                z-index: -11;
              }
            }
            & .ant-calendar-date:hover {
              box-shadow: none;
            }
          }

          & .ant-calendar-next-month-btn-day {
            & .ant-calendar-date {
              color: #828097;
              opacity: 0.5;
            }
          }
          & .ant-calendar-date:hover {
            box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
            border-radius: 4px;
            -webkit-border-radius: 4px;
            -moz-border-radius: 5px;
            background: #ffa100;
            color: #fff;
          }
        }
      }
      & .ant-calendar-footer {
        display: none;
      }
    }
    .ant-calendar-input-wrap {
      display: none;
    }
    .ant-calendar-disabled-cell.ant-calendar-today .ant-calendar-date::before {
      border: 0;
      top: 6px;
    }
  }
  .custom-timepicker {
    line-height: 46px;
    width: initial;
    border: 1px solid #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    input {
      ::placeholder {
        color: #828097;
        opacity: 1;
      }
      ::-ms-input-placeholder {
        color: #828097;
        opacity: 1;
      }
      ::-webkit-input-placeholder {
        color: #828097;
        opacity: 1;
      }
      ::-moz-placeholder {
        color: #828097;
        opacity: 1;
      }
    }
    .ant-time-picker-input {
      text-align: center;
      font-size: 15px;
      cursor: pointer;
      padding: 0;
      height: 46px;
      border: 0;
      &:focus {
        box-shadow: none;
      }
      @media ${size['mobile-md-max']} {
        left: -30px;
      }
    }
    .ant-time-picker-icon {
      display: none;
      right: 12px;
      @media ${size['laptop-md-max']} {
        right: 0px;
      }
      @media ${size['mobile-sm-max']} {
        right: 18px;
      }
    }
    .ant-time-picker-clock-icon {
      color: black;
      font-size: 15px;
      right: 18px;
    }
  }
  .pickupDateDiv {
    box-shadow: none;
    width: 20%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 45px;
    background: none;
    padding: 0;
    min-width: 70px;
  }
  .carrierAvailabilityDiv {
    box-shadow: none;
    background: none;
    padding: 0;
    font-size: 18px;
    font-weight: 300;
    color: #979797;
    min-width: 70px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 45px;
    width: 135px;
    @media ${size['desktop-mm-max']} {
      right: 40px;
      font-size: 16px;
    }
    @media ${size['tablet-max']} {
      font-size: 14px;
    }
    .ant-select-no-arrow .ant-select-selection__rendered {
      margin: 0px;
      line-height: 58px;
    }
    .ant-select-dropdown {
      min-width: 200px;
    }
  }
  .carrierAvailabilityDiv,
  .pickupDateDiv {
    .select-custom {
      padding: 0 !important;
    }
    .ant-select-selection__placeholder {
      color: #979797 !important;
      text-decoration: none !important;
      text-align: center;
      font-size: 18px;
      font-weight: 300;
    }
    .dropdown-custom ul li {
      padding-left: 14px;
    }
  }
  .suffix-wrapper {
    .dots-icon {
      max-height: 58px;
      border: 0;
      background: none;
      padding: 0;
      height: 100%;
      font-size: 22px;
      color: #8f48ff;
      letter-spacing: 0;
      position: absolute;
      right: 5px;
      z-index: 1;
      top: 3px;
      bottom: 0;
      @media ${size['desktop-sm-max']} {
        max-height: 42px;
      }
      @media ${size['tablet-max']} {
        max-height: 42px;
      }
      &:active,
      &:focus {
        outline: none;
      }
    }
  }

  .dt-tm-row {
    width: 100%;
    .first-col {
      padding: 0 !important;
    }
    .ant-col {
      padding-left: 10px;
      padding-top: 1px;
    }
  }
  .mainWindowSlider {
    margin: 36px 0 16px 14px;
  }
  .rc-slider-track.rc-slider-track-1 {
    background-color: #8f48ff;
  }
  .rc-slider-handle.rc-slider-handle-dragging {
    box-shadow: none !important;
    border: 8px solid rgb(143, 72, 255) !important;
    background-color: #fff !important;
  }
  .rc-slider-handle {
    height: 28px;
    width: 28px;
    margin-top: -14px;
    background-color: #fff;
    border: 8px solid rgb(143, 72, 255);
    right: auto;
    transform: translateX(-50%);
    &:hover,
    &:focus {
      border: 8px solid rgb(143, 72, 255);
      background-color: #fff;
    }
  }
`;

export { FormWrapper };
