import { push } from 'connected-react-router';
import isEmpty from 'lodash.isempty';
import { axiosAuthPost, axiosCsvAuthPost, axiosPost } from 'modules/Axios';
import { apiConstant } from 'modules/config';
import { loader } from 'redux/app/actions';
import store from 'redux/store';
import * as actions from './constant';

export const getJob = (payload) => async (dispatch) => {
  let status = false,
    data = {};
  try {
    dispatch(loader(true));
    dispatch({ type: actions.GETJOB_INITIATED });
    dispatch({ type: actions.GETSAMEDAYJOB_INITIATED });
    let response = await axiosAuthPost(apiConstant.GET_JOB, payload);
    if (!response.success) {
      dispatch({
        type: actions.GETJOB_ERROR,
        error: response.error.message,
      });
      dispatch({
        type: actions.GETSAMEDAYJOB_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.GETJOB_SUCCESS,
        payload: response.data,
      });
      dispatch({
        type: actions.GETSAMEDAYJOB_SUCCESS,
        payload: response.data,
      });
      status = true;
      if (payload.page === 'confirm-booking') {
        data = response.data;
        return data;
      }
    }
  } catch (error) {
    dispatch({
      type: actions.GETJOB_ERROR,
      error,
    });
    dispatch({
      type: actions.GETSAMEDAYJOB_ERROR,
      error,
    });
  } finally {
    dispatch(loader(false));
    if (payload.page == 'confirm-booking') {
      return { status, data };
    } else return status;
  }
};

// TODO - Cache get job call
export const backgroundGetJob = (payload) => async (dispatch) => {
  let status = false;
  try {
    dispatch(loader(true));
    dispatch({ type: actions.GETJOB_INITIATED });
    dispatch({ type: actions.GETSAMEDAYJOB_INITIATED });
    let response = await axiosAuthPost(apiConstant.GET_JOB, payload);
    if (!response.success) {
      dispatch({
        type: actions.GETJOB_ERROR,
        error: response.error.message,
      });
      dispatch({
        type: actions.GETSAMEDAYJOB_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.GETJOB_SUCCESS,
        payload: response.data,
      });
      dispatch({
        type: actions.GETSAMEDAYJOB_SUCCESS,
        payload: response.data,
      });
      status = true;
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: actions.GETJOB_ERROR,
      error,
    });
    dispatch({
      type: actions.GETSAMEDAYJOB_ERROR,
      error,
    });
  } finally {
    dispatch(loader(false));
    return status;
  }
};

export const addJob = (payload, state = null, type) => async (dispatch) => {
  let status = false;
  let storeRef = store.getState();
  let leg_type;
  if (payload.leg_type !== 0) {
    payload.leg_type = 1;
  }
  try {
    dispatch(loader(true));
    dispatch({ type: actions.ADDJOB_INITIATED });
    let response = await axiosAuthPost(apiConstant.ADD_JOB, {
      ...payload,
      // leg_type //Will be 0 for same day
    });
    if (!response.success) {
      dispatch({
        type: actions.ADDJOB_ERROR,
        error: response.error.message,
      });
    } else {
      await dispatch({
        type: actions.ADDJOB_SUCCESS,
        payload: response.data,
      });
      // status = true;
      status = response.data;
      if (state === '111' || state === '112') {
        await dispatch(convertToMultiJob({ id: response.data.id, multi_job_type: state }));
      }
      if (!payload.multi_job_id) {
        dispatch(
          push({
            pathname: `/booking/${response.data.id}`,
          })
        );
      }
      if (!isEmpty(storeRef) && !isEmpty(storeRef.booking) && !isEmpty(storeRef.booking.parcelAllAddress)) {
        await new Promise.all(
          storeRef.booking.parcelAllAddress.map(async (data) => {
            data.job_id = response.data.id;
            await dispatch(parcel(data));
          })
        );
      }
    }
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    dispatch({
      type: actions.ADDJOB_ERROR,
      error,
    });
  } finally {
    return status;
  }
};
export const updateJob = (payload, type) => async (dispatch) => {
  try {
    let status = false;
    let leg_type;
    if (type == 'sameday') {
      leg_type = 0;
    } else {
      leg_type = 1;
    }
    dispatch(loader(true));
    await dispatch({ type: actions.UPDATEJOB_INITIATED });
    let response = await axiosAuthPost(apiConstant.UPDATE_JOB, {
      ...payload,
      leg_type,
    });
    if (!response.success) {
      await dispatch({
        type: actions.UPDATEJOB_ERROR,
        error: response.error.message,
      });
    } else {
      await dispatch({
        type: actions.UPDATEJOB_SUCCESS,
        payload: response.data,
      });
      status = true;
    }
    dispatch(loader(false));
    return status;
  } catch (error) {
    dispatch(loader(false));
    await dispatch({
      type: actions.UPDATEJOB_ERROR,
      error,
    });
  }
};
export const clearJob = (payload) => async (dispatch) => {
  await dispatch({
    type: actions.CLEARJOB,
  });
};
export const cancelJob = (payload, isRedirect = true) => async (dispatch) => {
  try {
    let status = false;
    dispatch(loader(true));
    dispatch({ type: actions.CANCELJOB_INITIATED });
    let response = await axiosAuthPost(apiConstant.CANCEL_JOB, payload);
    if (!response.success) {
      dispatch({
        type: actions.CANCELJOB_ERROR,
        error: response.error.message,
      });
    } else {
      await dispatch({
        type: actions.CANCELJOB_SUCCESS,
        payload: response.data,
      });
      status = true;
      if (isRedirect) {
        dispatch({
          type: actions.CLEARJOB,
        });
        dispatch(
          push({
            pathname: '/booking',
          })
        );
        if (process.env.REACT_APP_ENABLE_SAMEDAY !== 'true' && process.env.REACT_APP_ENABLE_SHIFT !== 'true') {
          dispatch(changeJobType(1));
        } else dispatch(changeJobType(0));
      }
    }
    dispatch(loader(false));
    return status;
  } catch (error) {
    dispatch(loader(false));
    dispatch({
      type: actions.CANCELJOB_ERROR,
      error,
    });
  }
};
export const getQuota = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.GETQUOTA_INITIATED });
    let response = await axiosAuthPost(apiConstant.GET_QUOTA, payload);
    if (!response.success) {
      dispatch({
        type: actions.GETQUOTA_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.GETQUOTA_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: actions.GETQUOTA_ERROR,
      error,
    });
  }
};
export const getNextDayQuote = (payload) => async (dispatch) => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.GETNEXTDAYQUOTE_INITIATED });
    let response = await axiosAuthPost(apiConstant.GET_NEXTDAYQUOTE, payload);
    if (!response.success) {
      dispatch({
        type: actions.GETNEXTDAYQUOTE_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.GETNEXTDAYQUOTE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: actions.GETNEXTDAYQUOTE_ERROR,
      error,
    });
  } finally {
    dispatch(loader(false));
  }
};
export const booking = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.BOOKING_INITIATED });
    let response = await axiosAuthPost(apiConstant.ADD_JOB, payload);
    if (!response.success) {
      dispatch({
        type: actions.BOOKING_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.BOOKING_SUCCESS,
        payload: response,
      });
      dispatch(getcouriers(response));
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: actions.BOOKING_ERROR,
      error,
    });
  }
};
export const getnextdayestimate = (payload) => async (dispatch) => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.GET_NEXTDAYESTIMATE_INITIATED });
    let response = await axiosAuthPost(apiConstant.GET_NEXTDAY_ESTIMATE, payload);
    if (!response.success) {
      dispatch({
        type: actions.GET_NEXTDAYESTIMATE_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.GET_NEXTDAYESTIMATE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: actions.GET_NEXTDAYESTIMATE_ERROR,
      error,
    });
  } finally {
    dispatch(loader(false));
  }
};
export const getcouriers = (payload) => async (dispatch) => {
  try {
    const auth = localStorage.auth;
    if (auth) {
      const session_id = JSON.parse(auth).session_id;
      var data = {
        session_id: session_id,
        lat: payload.data.pickup_location_lat,
        lng: payload.data.pickup_location_lng,
      };
      dispatch({ type: actions.COURIERSLIST_INITIATED });
      let response = await axiosAuthPost('customer-status/get-couriers-around-me', data);
      if (!response.success) {
        dispatch({
          type: actions.COURIERSLIST_ERROR,
          error: response.error.message,
        });
      } else {
        dispatch({
          type: actions.COURIERSLIST_SUCCESS,
          payload: response,
        });
      }
    }
  } catch (error) {
    console.log(error);
    dispatch({
      type: actions.COURIERSLIST_ERROR,
      error,
    });
  }
};

//ANCHOR - Add Parcel
export const parcel = (payload) => async (dispatch) => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.PARCEL_INITIATED });
    let response = await axiosAuthPost(apiConstant.ADD_PARCEL, payload);
    if (!response.success) {
      dispatch({
        type: actions.PARCEL_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.PARCELADD_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    dispatch({
      type: actions.PARCEL_ERROR,
      error,
    });
  }
};

//ANCHOR - Add MultiJob Parcel
export const multiJobAddParcel = (payload, jobs_id) => async (dispatch) => {
  let parcelStatus = false;
  try {
    dispatch(loader(true));
    dispatch({ type: actions.PARCEL_INITIATED });

    let response = await Promise.all(
      jobs_id.map(async (id) => {
        payload.job_id = id;
        return await axiosAuthPost(apiConstant.ADD_PARCEL, payload);
      })
    );
    if (!isEmpty(response)) {
      response.map((res) => {
        if (res.success) {
          parcelStatus = true;
        }
      });
    }
    if (!parcelStatus) {
      dispatch({
        type: actions.PARCEL_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.PARCELADD_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: actions.PARCEL_ERROR,
      error,
    });
  } finally {
    dispatch(loader(false));
  }
};

// ANCHOR - Update Parcel
export const updateparcel = (payload) => async (dispatch) => {
  try {
    let status = false;
    dispatch(loader(true));
    dispatch({ type: actions.UPDATEPARCEL_INITIATED });
    let response = await axiosAuthPost(apiConstant.UPDATE_PARCELS, payload);
    if (!response.success) {
      dispatch({
        type: actions.UPDATEPARCEL_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.UPDATEPARCEL_SUCCESS,
        payload: response.data,
      });
      status = true;
    }
    dispatch(loader(false));
    return status;
  } catch (error) {
    dispatch(loader(false));
    dispatch({
      type: actions.UPDATEPARCEL_ERROR,
      error,
    });
  }
};

// ANCHOR - Update MultiJob Parcel
export const multiJobUpdateParcel = (payload, jobs_id) => async (dispatch) => {
  try {
    let status = false;
    dispatch(loader(true));
    dispatch({ type: actions.UPDATEPARCEL_INITIATED });
    let response = await axiosAuthPost(apiConstant.UPDATE_PARCELS, payload);
    if (!response.success) {
      dispatch({
        type: actions.UPDATEPARCEL_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.UPDATEPARCEL_SUCCESS,
        payload: response.data,
      });
      delete payload.job_id;
      await dispatch(multiJobAddParcel(payload, jobs_id));
      status = true;
    }
    dispatch(loader(false));
    return status;
  } catch (error) {
    dispatch(loader(false));
    dispatch({
      type: actions.UPDATEPARCEL_ERROR,
      error,
    });
  }
};
export const deleteParcel = (payload) => async (dispatch) => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.DELETEPARCEL_INITIATED });
    let response = await axiosAuthPost(apiConstant.DELETE_PARCELS, payload);
    if (!response.success) {
      dispatch({
        type: actions.DELETEPARCEL_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.DELETEPARCEL_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    dispatch({
      type: actions.DELETEPARCEL_ERROR,
      error,
    });
  }
};
export const getParcel = (payload) => async (dispatch) => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.GETPARCEL_INITIATED });
    let response = await axiosAuthPost(apiConstant.GET_PARCELS, payload);
    if (!response.success) {
      dispatch({
        type: actions.GETPARCEL_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.GETPARCEL_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: actions.GETPARCEL_ERROR,
      error,
    });
  } finally {
    dispatch(loader(false));
  }
};
export const getParcelforEdit = () => async (dispatch) => {
  try {
    dispatch({
      type: actions.GETPARCELFOREDIT_INITIATED,
    });
    dispatch({
      type: actions.GETPARCELFOREDIT_SUCCESS,
    });
  } catch (error) {
    console.log(error);
  }
};
export const addItem = (payload) => async (dispatch) => {
  try {
    let status = false;
    dispatch(loader(true));
    dispatch({ type: actions.ITEM_INITIATED });
    let response = await axiosAuthPost(apiConstant.ADD_ITEM, payload);
    if (!response.success) {
      dispatch({
        type: actions.ITEM_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.ITEMADD_SUCCESS,
        payload: response.data,
      });
      status = true;
    }
    dispatch(loader(false));
    return status;
  } catch (error) {
    dispatch(loader(false));
    dispatch({
      type: actions.ITEM_ERROR,
      error,
    });
  }
};
export const updateItem = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.UPDATEITEM_INITIATED });
    let response = await axiosAuthPost(apiConstant.UPDATE_ITEM, payload);
    if (!response.success) {
      dispatch({
        type: actions.UPDATEITEM_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.UPDATEITEMADD_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: actions.UPDATEITEM_ERROR,
      error,
    });
  }
};
export const deleteItem = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.DELETEITEM_INITIATED });
    let response = await axiosAuthPost(apiConstant.DELETE_ITEM, payload);
    if (!response.success) {
      dispatch({
        type: actions.DELETEITEM_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.DELETEITEM_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: actions.DELETEITEM_ERROR,
      error,
    });
  }
};
export const setNextDayService = (payload) => async (dispatch) => {
  let status = false;
  try {
    dispatch(loader(true));
    dispatch({ type: actions.SETNEXTDAYSERVICE_INITIATED });
    let response = await axiosAuthPost(apiConstant.SET_NEXTDAYSERVICE, payload);
    if (!response.success) {
      dispatch({
        type: actions.SETNEXTDAYSERVICE_ERROR,
        error: response.error.message,
      });
    } else {
      status = true;
      await dispatch({
        type: actions.SETNEXTDAYSERVICE_SUCCESS,
        payload: response.data,
      });
      // if (payload.parcel_store === 1) {
      //   await dispatch(
      //     getParcelStores({
      //       job_id: payload.job_id,
      //       service_id: payload.service_id
      //     })
      //   );
      // }
      //  else {
      //   dispatch(clearParcelStore());
      // }
    }
  } catch (error) {
    dispatch({
      type: actions.SETNEXTDAYSERVICE_ERROR,
      error,
    });
  } finally {
    dispatch(loader(false));
    return status;
  }
};
export const getParcelStores = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.GETPARCELSTORES_INITIATED });
    let response = await axiosAuthPost(apiConstant.GET_PARCELSTORES, payload);
    if (!response.success) {
      dispatch({
        type: actions.GETPARCELSTORES_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.GETPARCELSTORES_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: actions.GETPARCELSTORES_ERROR,
      error,
    });
  }
};
export const clearParcelStore = () => async (dispatch) => {
  dispatch({
    type: actions.CLEARPARCELSTORES,
  });
};
export const getCurrentjobs = (payload) => async (dispatch) => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.GETCURRENTJOB_INITIATED });
    let response = await axiosAuthPost(apiConstant.GET_CURRENTJOB, payload);
    if (!response.success) {
      dispatch({
        type: actions.GETCURRENTJOB_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.GETCURRENTJOB_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(loader(false));
    return response;
  } catch (error) {
    dispatch(loader(false));
    dispatch({
      type: actions.GETCURRENTJOB_ERROR,
      error,
    });
  }
};

export const removeDrafts = (id, currentJobs) => async (dispatch) => {
  let newJobs = JSON.parse(JSON.stringify(currentJobs));
  newJobs = newJobs.filter((job) => job.id !== id).filter((job) => job.multi_job_id !== id);

  dispatch({
    type: actions.REMOVEDRAFT,
    payload: newJobs,
  });
};

export const convertToMultiJob = (payload) => async (dispatch) => {
  let status = false;
  try {
    dispatch(loader(true));
    dispatch({ type: actions.CONVERTMULTIJOB_INITIATED });
    let response = await axiosAuthPost(apiConstant.CONVERT_MULTI_JOB, payload);
    if (response.success) {
      dispatch({
        type: actions.CONVERTMULTIJOB_SUCCESS,
        payload: response.data,
      });
      await dispatch(getParcel({ job_id: response.data.multi_job_id }));
      status = true;
    } else {
      dispatch({
        type: actions.CONVERTMULTIJOB_ERROR,
        error: response.error.message,
      });
    }
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
  } finally {
    return status;
  }
};

export const uploadCsv = (payload, currentState, type) => async (dispatch) => {
  let status = false;
  let leg_type = 0;
  if (currentState.leg_type !== 0) {
    leg_type = 1;
  }
  try {
    dispatch(loader(true));
    let response = await axiosAuthPost(apiConstant.UPLOAD_CSV, {
      get_upload_url: '1',
      leg_type,
      earliest_pickup_time: currentState.earliest_pickup_time,
      delivery_deadline: currentState.delivery_deadline,
    });
    payload.multi_job_type = currentState.multi_job_type;
    payload.leg_type = leg_type;
    payload.earliest_pickup_time = currentState.earliest_pickup_time;
    payload.delivery_deadline = currentState.delivery_deadline;
    if (currentState.job_id) payload.job_id = currentState.job_id;
    let res = await axiosCsvAuthPost(response.data.upload_url, payload);
    if (res.status === 200) {
      if (res.data.success) {
        dispatch({
          type: 'getCsvStatus',
          payload: res.data,
        });
        status = true;
      }
    }
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
  } finally {
    return status;
  }
};
export const setMultiCurrentAddress = (payload) => async (dispatch) => {
  dispatch({
    type: actions.SET_MULTI_CURRENT_ADD,
    payload,
  });
  setTimeout(() => {
    dispatch({
      type: actions.CLEAR_MULTI_CURRENT_ADD,
    });
  }, 100);
};
export const setFilteredData = (payload) => async (dispatch) => {
  dispatch({
    type: actions.SET_FILTERED_DATA,
    payload,
  });
};

export const addParcelToNewAddress = (payload) => async (dispatch) => {
  if (payload.job_id) delete payload.job_id;
  if (payload.parcel_id) delete payload.parcel_id;
  dispatch({
    type: actions.ADD_PARCEL_NEWADDRESS,
    payload,
  });
};

export const addSelectedJobId = (payload) => async (dispatch) => {
  dispatch({
    type: actions.ADD_SELECTED_JOB_ID_PARCEL,
    payload,
  });
};

export const editAddressForJob = (payload) => async (dispatch) => {
  dispatch({
    type: actions.EDIT_ADDRESS_DATA,
    payload,
  });
};

export const editParcelForJob = (payload) => async (dispatch) => {
  dispatch({
    type: actions.EDIT_PARCEL_DATA,
    payload,
  });
};

export const selectedJobIdForParcelAdd = (payload) => async (dispatch) => {
  dispatch({
    type: actions.SELECTEDJOBID_PARCEL_ADD,
    payload,
  });
};

export const lastAddedParcelAddress = (payload) => async (dispatch) => {
  dispatch({
    type: 'lastParcelAddress',
    payload,
  });
};

export const isClicked = (payload) => async (dispatch) => {
  dispatch({
    type: 'ButtonClicked',
    payload,
  });
};

export const convertJob = (payload) => async (dispatch) => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.CONVERT_SAME_NEXT_DAY_INITIATED });
    let response = await axiosAuthPost(apiConstant.CONVERT_SAME_NEXT_DAY, payload);
    if (!response.success) {
      dispatch({
        type: actions.CONVERT_SAME_NEXT_DAY_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.CONVERT_SAME_NEXT_DAY_SUCCESS,
        payload: response.data,
      });
      // dispatch(getJob({ id: payload.id }));
    }
    dispatch(loader(false));
    return response;
  } catch (error) {
    dispatch(loader(false));
    dispatch({
      type: actions.CONVERT_SAME_NEXT_DAY_ERROR,
      error,
    });
  }
};

export const privateGetCouriersNearMe = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.PRIVATE_GETCOURIERS_AROUND_ME_INITIATED });
    let response = await axiosAuthPost(apiConstant.PRIVATE_GETCOURIERS_AROUND_ME, payload);
    if (!response.success) {
      dispatch({
        type: actions.PRIVATE_GETCOURIERS_AROUND_ME_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.PRIVATE_GETCOURIERS_AROUND_ME_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: actions.PRIVATE_GETCOURIERS_AROUND_ME_ERROR,
      error,
    });
  }
};

export const publicGetCouriersNearMe = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.PUBLIC_GETCOURIERS_AROUND_ME_INITIATED });
    let response = await axiosPost(apiConstant.PUBLIC_GETCOURIERS_AROUND_ME, payload);
    if (!response.success) {
      dispatch({
        type: actions.PUBLIC_GETCOURIERS_AROUND_ME_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.PUBLIC_GETCOURIERS_AROUND_ME_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: actions.PUBLIC_GETCOURIERS_AROUND_ME_ERROR,
      error,
    });
  }
};

export const optimiseSequence = (payload) => async (dispatch) => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.OPTIMISE_SEQUENCE_INITIATED });
    let response = await axiosAuthPost(apiConstant.OPTIMISE_SEQUENCE, payload);
    if (!response.success) {
      dispatch({
        type: actions.OPTIMISE_SEQUENCE_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.OPTIMISE_SEQUENCE_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    dispatch({
      type: actions.OPTIMISE_SEQUENCE_ERROR,
      error,
    });
  }
};

export const changeSequence = (payload) => async (dispatch) => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.CHANGE_SEQUENCE_INITIATED });
    let response = await axiosAuthPost(apiConstant.CHANGE_SEQUENCE, payload);
    if (!response.success) {
      dispatch({
        type: actions.CHANGE_SEQUENCE_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.CHANGE_SEQUENCE_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    dispatch({
      type: actions.CHANGE_SEQUENCE_ERROR,
      error,
    });
  }
};

export const getWorkingHours = (payload) => async (dispatch) => {
  try {
    dispatch({ type: actions.GET_WORKING_HOURS_INITIATED });
    let response = await axiosPost(apiConstant.GET_WORKING_HOURS, payload);
    if (!response.success) {
      dispatch({
        type: actions.GET_WORKING_HOURS_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.GET_WORKING_HOURS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: actions.GET_WORKING_HOURS_ERROR,
      error,
    });
  }
};

export const validateJob = (payload) => async (dispatch) => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.VALIDATEJOB_INITIATED });
    let response = await axiosAuthPost(apiConstant.VALIDATE_JOB, payload);
    if (!response.success) {
      dispatch({
        type: actions.VALIDATEJOB_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.VALIDATEJOB_SUCCESS,
        payload: response.data,
      });
    }
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    dispatch({
      type: actions.VALIDATEJOB_ERROR,
      error,
    });
  }
};

export const updateCourier = (payload) => (dispatch) => {
  dispatch({
    type: actions.UPDATE_COURIER,
    payload,
  });
};

export const changeJobType = (payload) => (dispatch) => {
  dispatch({
    type: actions.CHANGEJOBTYPE,
    payload,
  });
};

export const waitAndReturn = (payload) => (dispatch) => {
  dispatch({
    type: actions.WAITANDRETURN,
    payload,
  });
};

export const pickUpPayload = (payload) => (dispatch) => {
  dispatch({
    type: actions.PICKUPPAYLOAD,
    payload,
  });
};

export const jobCourierManifest = (payload) => async (dispatch) => {
  try {
    let response = await axiosAuthPost(apiConstant.GET_JOB_COURIER_MANIFEST, payload);
    if (response.success)
      dispatch({
        type: actions.JOB_COURIER_MANIFEST,
        payload: response.data,
      });
  } catch (error) {
    console.log(error);
  }
};

export const jobCourierLocation = (payload) => async (dispatch) => {
  try {
    let response = await axiosAuthPost(apiConstant.GET_JOB_COURIER_LOCATION, payload);
    if (response.success)
      dispatch({
        type: actions.JOB_COURIER_LOCATION,
        payload: response.data,
      });
  } catch (error) {
    console.log(error);
  }
};

export const rateCourier = (payload) => async (dispatch) => {
  try {
    dispatch(loader(true));
    let response = await axiosAuthPost(apiConstant.RATE_COURIER, payload);
    if (response.success)
      dispatch({
        type: actions.RATE_COURIER_FEEDBACK,
        payload: response.data,
      });
    dispatch(loader(false));
  } catch (error) {
    dispatch(loader(false));
    console.log(error);
  }
};

export const showTimerfromPubnub = (payload) => async (dispatch) => {
  dispatch({
    type: actions.SHOW_TIMER_FROM_PUBNUB,
    payload,
  });
};
export const showFeedBackfromPubnub = (payload) => async (dispatch) => {
  dispatch({
    type: actions.SHOW_FEEDBACK_FROM_PUBNUB,
    payload,
  });
};

export const showLoaderfromPubnub = (payload) => async (dispatch) => {
  dispatch({
    type: actions.SHOW_LOADER_FROM_PUBNUB,
    payload,
  });
};
export const getSameDayLabels = (payload) => async (dispatch) => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.GET_SAME_DAY_LABELS_INITIATED });
    let response = await axiosAuthPost(apiConstant.GET_SAME_DAY_LABELS, payload);
    if (!response.success) {
      dispatch({
        type: actions.GET_SAME_DAY_LABELS_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.GET_SAME_DAY_LABELS_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: actions.GET_SAME_DAY_LABELS_ERROR,
      error,
    });
  } finally {
    dispatch(loader(false));
  }
};
export const getLabelZipFile = (payload) => async (dispatch) => {
  try {
    dispatch(loader(true));
    dispatch({ type: actions.GET_LABEL_ZIP_FILE_INITIATED });
    let response = await axiosAuthPost(apiConstant.GET_LABEL_ZIP_FILE, payload);
    if (!response.success) {
      dispatch({
        type: actions.GET_LABEL_ZIP_FILE_ERROR,
        error: response.error.message,
      });
    } else {
      dispatch({
        type: actions.GET_LABEL_ZIP_FILE_SUCCESS,
        payload: response.data,
      });
    }
  } catch (error) {
    dispatch({
      type: actions.GET_LABEL_ZIP_FILE_ERROR,
      error,
    });
  } finally {
    dispatch(loader(false));
  }
};