import * as actions from './constant';

const initialState = {
  addFavourite: false,
  loading: false,
  favourites: [],
  deleteStatus: false,
  geoCode: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.ADDFAVOURITE_INITIATED:
      return {
        ...state,
        favourites: [...state.favourites],
        loading: true,
        addFavourite: false
      };
    case actions.ADDFAVOURITE_SUCCESS:
      return {
        ...state,
        loading: false,
        addFavourite: true
      };
    case actions.ADDFAVOURITE_ERROR:
      return {
        ...state,
        loading: false,
        addFavourite: false
      };
    case actions.GETFAVOURITES_INITIATED:
      return {
        ...state,
        favourites: [],
        loading: true,
        addFavourite: false
      };
    case actions.GETFAVOURITES_SUCCESS:
      return {
        ...state,
        loading: false,
        favourites: action.payload
      };
    case actions.GETFAVOURITES_ERROR:
      return {
        ...state,
        favourites: [],
        loading: false,
        addFavourite: false
      };
    case actions.DELETEFAVOURITES_INITIATED:
      return {
        ...state,
        deleteStatus: false
      };
    case actions.DELETEFAVOURITES_SUCCESS:
      return {
        ...state,
        deleteStatus: true
      };
    case actions.DELETEFAVOURITES_ERROR:
      return {
        ...state,
        deleteStatus: false
      };

    case actions.ADDNEWADDRESSFAVOURITES_INITIATED:
      return {
        ...state,
        geoCode: [],
        loading: true
      };
    case actions.ADDNEWADDRESSFAVOURITES_SUCCESS:
      return {
        ...state,
        geoCode: action.payload,
        loading: false
      };
    case actions.ADDNEWADDRESSFAVOURITES_ERROR:
      return {
        ...state,
        geoCode: [],
        loading: false
      };
    default:
      return state;
  }
};
