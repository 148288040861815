import { BookingConstant } from 'container/Booking/constant';
import BikeIconWhite from '../../assets/images/vehicles/bike.svg';
import CarIconWhite from '../../assets/images/vehicles/car.svg';
import CargoBikeIconWhite from '../../assets/images/vehicles/cargo_bike.svg';
import LargeVanIconWhite from '../../assets/images/vehicles/large_van.svg';
import MotorBikeIconWhite from '../../assets/images/vehicles/motorbike.svg';
import SmallCarIconWhite from '../../assets/images/vehicles/small_van.svg';

const bicycle = require('../../assets/images/vehicles/bike-purple.svg');
const cargoBike = require('../../assets/images/vehicles/cargo_bike_purple.svg');
const motorCycle = require('../../assets/images/vehicles/motorbike-purple.svg');
const car = require('../../assets/images/vehicles/car-purple.svg');
const smallVan = require('../../assets/images/vehicles/small_van_purple.svg');
const largeVan = require('../../assets/images/vehicles/large_van_purple.svg');

// const BikeIconWhite = require('../../assets/images/vehicles/bike.svg').default;
// const CargoBikeIconWhite = require('../../assets/images/vehicles/cargo_bike.svg').default;
// const MotorBikeIconWhite = require('../../assets/images/vehicles/motorbike.svg').default;
// const CarIconWhite = require('../../assets/images/vehicles/car.svg').default;
// const SmallCarIconWhite = require('../../assets/images/vehicles/small_van.svg').default;
// const LargeVanIconWhite = require('../../assets/images/vehicles/large_van.svg').default;
const BookingDetailsConstant = {
  tryBooking: 'Try Booking Near You',
  seeWhat: 'See what we can offer',
  findBooking: 'Find My Booking',
  provider: 'Provider',
  priceRange: 'Price range',
  companyRating: 'Company Rating',
  pickDrop: 'Direct Pick Up and Drop Off',
  insurance: '£50 to £500 Insurance.',
  onlineTracking: 'Online Tracking',
  completeBooking: 'Complete your booking',
  couriersIn: 'Couriers in',
  UK: 'United Kingdom',
  totalPrice: 'Total price includes taxes + fees. Additional fees will apply after',
  completingBooking: 'completing the booking process.',
  sort: 'Sort',
  pound: '£',
  gophrCourierBooking: 'Booking',
  shippingRequirements: 'Service Requirements',
  orderPreview: 'Order Preview',
  nextDaysingle: 'NEXT DAY | SINGLE DELIVERY',
  sameDaysingle: 'SAME DAY | SINGLE DELIVERY',
  deliveryDay: 'Delivery Day',
  jobType: 'Service type',
  pickUpDate: 'PICK UP DATE',
  carrierAvailability: 'CARRIER AVAILABILITY',
  Availability: 'AVAILABILITY',
  location: 'LOCATION',
  pickUp: 'PICK UP',
  dropoff: 'DROP OFF',
  return: 'RETURN',
  parcelsPreview: 'Parcels Preview',
  availableCouriers: 'Available Couriers',
  nextDay: 'NEXT DAY | PICK UP - DROP OFF',
  yourPick: 'YOUR PICK',
  price: 'PRICE',
  rating: 'RATING',
  othercouries: 'OTHER COURIERS',
  orderReceipt: 'Order Receipt',
  orderQuote: 'Order Quote',
  yourReceipt: 'YOUR QUOTE',
  courierGov: 'VAT',
  totalPricetxt: 'TOTAL PRICE',
  pricesChange: 'TOTAL',
  routePreview: 'Route Preview',
  vehicleSelection: 'VEHICLE AND TIMING',
  priceSubject: 'Total vat',
  totalNet: 'Total net',
  totalGross: 'Total gross',
  address: 'ADDRESS',
  parcels: 'PARCELS',
  parcel: 'parcel',
  moveParcelTo: 'Move Parcel To',
  directPickDrop: 'Direct Pick Up and Drop Off',
  couriersInsurance: '£50 to £500 Insurance.',
  onlineTracking: 'Online Tracking',
  collectionDeliveryReport: 'Collection / Delivery Report',
  printerRequired: 'Printer Required',
  chooseCourier: 'Choose this courier',
  to: 'TO',
  countBefore: ' (',
  countafter: ')',
  dateLocation: 'DATE AND LOCATION',
  courier: 'COURIERS',
  courierGovPrice: 0,
  drivers: 'NUMBER OF DRIVERS',
  vehicle: 'VEHICLE',
  vehiclesanddrivers: 'Vehicles and Drivers',
  specialCare: 'Special Care',
  shiftDetails: 'Shift Details',
  fragile: 'FRAGILE',
  food: 'Food and liquid',
  description: 'DESCRIPTION',
  workProof: 'PROOF OF WORK',
  handleDescription: 'SHIFT DESCRIPTION',
  start: 'STARTING ADDRESS',
  pin: 'PIN ADDRESS',
};

const Bookingplaceholder = {
  pickUp: 'Pick Up Adresss',
  weight: '250 Ibs',
  size: '00 in × 00 in× 00 in',
  itemInsurance: 'Item Insurance',
  referenceNumber: '000000000',
  value: '£00.00',
  itemDescription: 'Item Description',
  amount: '00',
  referenceNumber: '000000000',
  quantity: 'Quantity',
  parcels: 'Parcels',
  ddmmyyyy: 'DD-MM-YYYY',
  Unspecified: 'Unspecified',
};

const settingParcel = ['Edit Parcel', 'Remove Parcel'];

const settingItem = ['Edit Item', 'Remove Item'];

const settingLocation = ['Edit Location', 'Remove Location'];

const settingOnlyCancelLocation = ['Edit Location', 'Delete this job'];

const settingCancelLocation = ['Edit Location', 'Remove Location', 'Delete this job'];

const settingMarketItem = ['Edit Item'];

const settingMarketParcel = ['Edit Parcel'];

const settingMarketLocation = ['Edit Location'];

const parcels = ['Kitchen Stuff', 'Kitchen Stuff 1', 'Kitchen Stuff 2', 'Kitchen Stuff 3'];

const weightData = [
  // { name: 'Custom', value: 'custom', file: 'custom-size.png' },
  {
    name: '≤ 100g',
    value: 0.1,
    lb: 0.220462262,
    file: 'weight-iphone.png',
    label: 'Phone',
  },
  //{name: '0.5 Kg',  value:  0.50},
  {
    name: '≤ 750g',
    value: 0.75,
    lb: 1.65346697,
    file: 'weight-bread.png',
    label: 'Loaf of bread',
  },
  //{name: '1 Kg',    value:  1.00},
  {
    name: '≤ 1.5 Kg',
    value: 1.5,
    lb: 3.30693393,
    file: 'weight-flour.png',
    label: 'Bag of flour',
  },
  {
    name: '≤ 3 Kg',
    value: 3.0,
    lb: 6.61386787,
    file: 'weight-water.png',
    label: '3 bottles of water',
  },
  {
    name: '≤ 5 Kg',
    value: 5.0,
    lb: 11.0231131,
    file: 'weight-paint.png',
    label: 'Can of paint',
  },
  //{name: '10 Kg',   value: 10.00},
  {
    name: '≤ 10 Kg',
    value: 10.0,
    lb: 26.4554715,
    file: 'weight-fireextinguisher.png',
    label: 'Fire extinguisher',
  },
  {
    name: '≤ 20 Kg',
    value: 20.0,
    lb: 44.0924524,
    file: 'weight-fireextinguisher.png',
    label: 'Fire extinguisher',
  },
];
const sameDayWeightData = [
  // { name: 'Custom', value: 'custom', file: 'custom-size.png' },
  {
    name: '≤ 100g',
    value: 0.1,
    lb: 0.220462262,
    file: 'weight-iphone.png',
    label: 'Phone',
  },
  //{name: '0.5 Kg',  value:  0.50},
  {
    name: '≤ 750g',
    value: 0.75,
    lb: 1.65346697,
    file: 'weight-bread.png',
    label: 'Loaf of bread',
  },
  //{name: '1 Kg',    value:  1.00},
  {
    name: '≤ 1.5 Kg',
    value: 1.5,
    lb: 3.30693393,
    file: 'weight-flour.png',
    label: 'Bag of flour',
  },
  {
    name: '≤ 3 Kg',
    value: 3.0,
    lb: 6.61386787,
    file: 'weight-water.png',
  },
  {
    name: '≤ 5 Kg',
    value: 5.0,
    lb: 11.0231131,
    file: 'weight-paint.png',
    label: 'Can of paint',
  },
  //{name: '10 Kg',   value: 10.00},
  {
    name: '≤ 10 Kg',
    value: 10.0,
    lb: 26.4554715,
    file: 'weight-fireextinguisher.png',
    label: 'Fire extinguisher',
  },
  {
    name: 'Custom Size',
    value: null,
  },
];

const sizeData = [
  // {name: 'Custom',x: 'custom', y: 'custom',z: 'custom', file: 'custom-size.png'},
  {
    name: 'Envelope',
    x: 25,
    x_in: 9.84251969,
    y: 16.5,
    y_in: 6.49606299,
    z: 1,
    z_in: 0.19685,
    file: 'size-envelope.png',
    index: 1,
  },
  {
    name: 'Large Envelope',
    x: 35,
    x_in: 13.7795276,
    y: 25,
    y_in: 9.84251969,
    z: 2.5,
    z_in: 0.984252,
    file: 'size-large-envelope.png',
    index: 2,
  }, //Standard jiffy
  {
    name: 'Small Package',
    x: 25,
    x_in: 9.84251969,
    y: 20,
    y_in: 7.87402,
    z: 7,
    z_in: 2.75591,
    file: 'size-small-package.png',
    index: 3,
  }, //Tome
  {
    name: 'Medium Package',
    x: 30,
    x_in: 11.811,
    y: 25,
    y_in: 9.84251969,
    z: 15,
    z_in: 5.90551,
    file: 'size-medium-package.png',
    index: 4,
  }, //Shoe box
  {
    name: 'Large Package',
    x: 40,
    x_in: 15.748,
    y: 30,
    y_in: 11.811,
    z: 30,
    z_in: 11.811,
    file: 'size-large-package.png',
    index: 5,
  }, //20 bottles of beer
  // {
  //   name: 'Poster Tube',
  //   x: 90,
  //   x_in: 35.4331,
  //   y: 20,
  //   y_in: 7.87402,
  //   z: 20,
  //   z_in: 7.87402,
  //   file: 'size-poster-tube.png',
  //   index: 6
  // }
  {
    name: 'X-Large Package',
    x: 50,
    x_in: 23.622,
    y: 50,
    y_in: 19.685,
    z: 60,
    z_in: 19.685,
    file: 'size-x-large-package.png',
    index: 6,
  }, //Mini-fridge
  {
    name: 'X-Large DPD Package',
    x: 60,
    x_in: 23.622,
    y: 60,
    y_in: 23.622,
    z: 60,
    z_in: 23.622,
    file: 'size-x-large-package.png',
    index: 7,
  }, //Mini-fridge
  // {
  //   name: 'XX-Large Package',
  //   x: 120,
  //   x_in: 47.2441,
  //   y: 75,
  //   y_in: 29.5276,
  //   z: 60,
  //   z_in: 23.622,
  //   file: 'size-xx-large-package.png',
  //   index: 8
  // }, //Desk
  // {
  //   name: 'XXX-Large Package',
  //   x: 180,
  //   x_in: 70.8661,
  //   y: 100,
  //   y_in: 39.3701,
  //   z: 80,
  //   z_in: 31.4961,
  //   file: 'size-xxx-large-package.png',
  //   index: 9
  // }, //2 Seat Sofa
  // {
  //   name: 'Large Cargo',
  //   x: 200,
  //   x_in: 78.7402,
  //   y: 150,
  //   y_in: 59.0551,
  //   z: 50,
  //   z_in: 19.685,
  //   file: 'size-large-cargo.png',
  //   index: 10
  // }, //Double bed
  {
    name: 'Custom Size',
    x: null,
    y: null,
    z: null,
    index: 11,
  },
];
const sameDaySizeData = [
  // {name: 'Custom',x: 'custom', y: 'custom',z: 'custom', file: 'custom-size.png'},
  {
    name: 'Envelope',
    x: 25,
    x_in: 9.84251969,
    y: 16.5,
    y_in: 6.49606299,
    z: 1,
    z_in: 0.19685,
    file: 'size-envelope.png',
    index: 1,
  },
  {
    name: 'Large Envelope',
    x: 35,
    x_in: 13.7795276,
    y: 25,
    y_in: 9.84251969,
    z: 2.5,
    z_in: 0.984252,
    file: 'size-large-envelope.png',
    index: 2,
  }, //Standard jiffy
  {
    name: 'Small Package',
    x: 25,
    x_in: 9.84251969,
    y: 20,
    y_in: 7.87402,
    z: 7,
    z_in: 2.75591,
    file: 'size-small-package.png',
    index: 3,
  }, //Tome
  {
    name: 'Medium Package',
    x: 30,
    x_in: 11.811,
    y: 25,
    y_in: 9.84251969,
    z: 15,
    z_in: 5.90551,
    file: 'size-medium-package.png',
    index: 4,
  }, //Shoe box
  {
    name: 'Large Package',
    x: 40,
    x_in: 15.748,
    y: 30,
    y_in: 11.811,
    z: 30,
    z_in: 11.811,
    file: 'size-large-package.png',
    index: 5,
  }, //20 bottles of beer
  {
    name: 'Poster Tube',
    x: 90,
    x_in: 35.4331,
    y: 20,
    y_in: 7.87402,
    z: 20,
    z_in: 7.87402,
    file: 'size-poster-tube.png',
    index: 6,
  },
  {
    name: 'X-Large Package',
    x: 60,
    x_in: 23.622,
    y: 50,
    y_in: 19.685,
    z: 50,
    z_in: 19.685,
    file: 'size-x-large-package.png',
    index: 7,
  }, //Mini-fridge
  {
    name: 'XX-Large Package',
    x: 120,
    x_in: 47.2441,
    y: 75,
    y_in: 29.5276,
    z: 60,
    z_in: 23.622,
    file: 'size-xx-large-package.png',
    index: 8,
  }, //Desk
  {
    name: 'XXX-Large Package',
    x: 180,
    x_in: 70.8661,
    y: 100,
    y_in: 39.3701,
    z: 80,
    z_in: 31.4961,
    file: 'size-xxx-large-package.png',
    index: 9,
  }, //2 Seat Sofa
  {
    name: 'Large Cargo',
    x: 200,
    x_in: 78.7402,
    y: 150,
    y_in: 59.0551,
    z: 50,
    z_in: 19.685,
    file: 'size-large-cargo.png',
    index: 10,
  }, //Double bed
  {
    name: 'Custom Size',
    x: null,
    y: null,
    z: null,
    index: 11,
  },
];
const insuranceList = [
  {
    name: 'No insurance',
    value: 0.0,
  },
  {
    name: 'UP TO £100',
    value: 100.0,
  },
  {
    name: 'UP TO £1000',
    value: 1000.0,
  },
  {
    name: 'UP TO £2000',
    value: 2000.0,
  },
  {
    name: 'UP TO £3000',
    value: 3000.0,
  },
  {
    name: 'UP TO £4000',
    value: 4000.0,
  },
  {
    name: 'UP TO £5000',
    value: 5000.0,
  },
  {
    name: 'Custom Insurance',
    value: null,
  },
];

const deliveryDay = ['Same Day', 'Next Day', 'Shift'];

const pickUpData = ['10:00', '11:00', '12:00', '13:00', '14:00', '15:00', '16:00'];

const jobTypeData = ['Single Location', 'Multiple Pick Up Locations', 'Multiple Delivery Locations'];

const nextDayjobTypeData = ['Single Location', 'Multiple Delivery Locations'];

const driversData = ['10 Drivers', '20 Drivers', '30 Drivers', '40 Drivers', '50 Drivers'];

const sequenceType = ['Flexible', 'Fixed'];

const vehiclesData = [
  {
    label: 'Bicycle',
    value: 10,
    file: bicycle,
  },
  {
    label: 'Cargo Bike',
    value: 15,
    file: cargoBike,
  },
  {
    label: 'Motorcycle',
    value: 20,
    file: motorCycle,
  },
  {
    label: 'Car',
    value: 30,
    file: car,
  },
  {
    label: 'Small Van',
    value: 40,
    file: smallVan,
  },
  {
    label: 'Large Van',
    value: 50,
    file: largeVan,
  },
];

const vehcileSelectionData = [
  {
    label: 'Bike',
    file: BikeIconWhite,
    weight: 'Up to 5kg',
    price: '£25',
    value: 10,
    size: `30 ${BookingConstant.multiply} 25 ${BookingConstant.multiply} 15 cms`,
    id: 0,
    left: '4px',
    width: '83%',
    name: 'bike',
  },
  {
    label: 'Cargo Bike',
    file: CargoBikeIconWhite,
    weight: 'Up to 25kg',
    price: '£25',
    value: 15,
    size: `100 ${BookingConstant.multiply} 100 ${BookingConstant.multiply} 100 cms`,
    id: 1,
    left: '-2px',
    width: '83%',
    name: 'cargo-bike',
  },
  {
    label: 'Motorcycle',
    file: MotorBikeIconWhite,
    weight: 'Up to 15kg',
    price: '£25',
    value: 20,
    size: `40 ${BookingConstant.multiply} 30 ${BookingConstant.multiply} 30 cms`,
    id: 2,
    left: '7px',
    width: '80%',
    name: 'motor-cycle',
  },
  {
    label: 'Car',
    file: CarIconWhite,
    weight: 'Up to 25kg',
    price: '£25',
    value: 30,
    id: 3,
    size: `60 ${BookingConstant.multiply} 40 ${BookingConstant.multiply} 40 cms`,
    left: '0px',
    width: '100%',
    name: 'car',
  },
  {
    label: 'Small Van',
    file: SmallCarIconWhite,
    weight: 'Up to 200kg',
    price: '£25',
    value: 40,
    size: `150 ${BookingConstant.multiply} 120 ${BookingConstant.multiply} 90 cms`,
    id: 4,
    left: '4px',
    width: '83%',
    name: 'small-van',
  },
  {
    label: 'Large Van',
    file: LargeVanIconWhite,
    weight: 'Up to 300kg',
    size: `200 ${BookingConstant.multiply} 150 ${BookingConstant.multiply} 120 cms`,
    price: '£25',
    value: 50,
    id: 5,
    left: '4px',
    width: '83%',
    name: 'large-van',
  },
];

const fragileList = [
  {
    label: 'Fragile',
    value: 'is_fragile',
  },
  {
    label: 'Glass',
    value: 'is_glass',
  },
  {
    label: 'Medical',
    value: 'is_medical',
  },
  {
    label: 'Flowers',
    value: 'is_flower',
  },
  {
    label: 'Do not rotate',
    value: 'is_not_rotatable',
  },
];

const foodList = [
  {
    label: 'Hot Food',
    value: 'is_food',
  },
  {
    label: 'Liquid Item',
    value: 'is_liquid',
  },
  {
    label: 'Alcohol',
    value: 'is_alcohol',
  },
  {
    label: 'Pork',
    value: 'is_pork',
  },
  {
    label: 'Beef',
    value: 'is_beef',
  },
  {
    label: 'Baked Goods',
    value: 'is_baked',
  },
];

const discriptionList = ['Description 1', 'Description 2', 'Description 3', 'Description 4'];

const WeekDays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
const months = ['Jan', 'Feb', 'Mar', 'Aprl', 'May', 'June', 'July', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
export {
  BookingDetailsConstant,
  Bookingplaceholder,
  settingParcel,
  settingLocation,
  settingItem,
  weightData,
  sizeData,
  parcels,
  insuranceList,
  deliveryDay,
  jobTypeData,
  pickUpData,
  driversData,
  vehiclesData,
  fragileList,
  foodList,
  discriptionList,
  settingMarketItem,
  settingCancelLocation,
  settingMarketLocation,
  settingMarketParcel,
  nextDayjobTypeData,
  sequenceType,
  WeekDays,
  vehcileSelectionData,
  months,
  settingOnlyCancelLocation,
  sameDaySizeData,
  sameDayWeightData,
};

