import { Spin } from 'antd';
import { ReactComponent as LogoPurple } from 'assets/images/logo-purple.svg';
import HigherOrder from 'components/HOC';
import PageLoader from 'components/PageLoader';
import { GoogleApiWrapper } from 'google-maps-react';
import isEmpty from 'lodash.isempty';
import { onError } from 'modules/errorHandler';
import { getAuthToken } from 'modules/helper';
import React, { Component } from 'react';
import Intercom from 'react-intercom';
import { connect } from 'react-redux';
import { screenWidthChange, setGoogleReference } from 'redux/app/actions';
import { ThemeProvider } from 'styled-components';
import Routes from '../routes';
import GlobalStyle from './app.style';
import AppComponent from './AppComponent';
import { lightTheme } from './theme';

export class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  async componentDidMount() {
    try {
      const { google, setGoogleReference } = this.props;
      if (google) {
        await setGoogleReference(google);
      }
      window.addEventListener('resize', this.resize);
      this.resize();
    } catch (error) {
      onError(error, 'DashApp', 'Mount Error');
    }
  }
  resize = () => {
    this.props.screenWidthChange(window.innerWidth);
  };

  componentDidCatch(error) {
    onError(error, 'App', 'Component render error');
  }

  render() {
    const { userData, isLoading } = this.props;
    let user = {};
    if (!isEmpty(userData) && !isEmpty(getAuthToken())) {
      user = {
        user_id: JSON.parse(localStorage.auth).intercom_id,
        user_hash: JSON.parse(localStorage.auth).intercom_hash,
        email: userData.email,
        name: userData.firstname,
        frontend_version: 'REACT_APP_FRONTEND_VERSION'
      };
    }

    return (
      <Spin
        spinning={isLoading > 0}
        size="large"
        className="spinner-custom"
        indicator={
          <div className="main-spin-loader-custom-wrapper">
            <div className="loader-custom"></div>
            <LogoPurple />
          </div>
        }
      >
        <Intercom appID={process.env.REACT_APP_INTERCOM_ID} {...user} />
        <ThemeProvider theme={lightTheme}>
          <GlobalStyle />
          <AppComponent />
          <Routes />
        </ThemeProvider>
      </Spin>
    );
  }
}
const mapStateToProps = state => ({
  userData: state.profileUpdate.userData,
  loading: state.app.loading,
  isLoading: state.app.isLoading,
  error: state.app.error
});
const mapStateToDispatch = dispatch => ({
  setGoogleReference: payload => dispatch(setGoogleReference(payload)),
  screenWidthChange: payload => dispatch(screenWidthChange(payload))
});

export default HigherOrder(
  connect(
    mapStateToProps,
    mapStateToDispatch
  )(
    GoogleApiWrapper({
      LoadingContainer: PageLoader,
      apiKey: process.env.REACT_APP_MAPS_KEYS,
      libraries: ['places, geometry']
    })(App)
  )
);
