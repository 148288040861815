import * as actions from './constant';
const initialState = {
  isResetPassword: false,
  error: false,
  loading: false,
  message: false
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actions.RESETPASSWORD_INITIATED:
      return {
        ...state,
        isResetPassword: false,
        message: false,
        error: false,
        loading: true
      };
    case actions.RESETPASSWORD_SUCCESS:
      return {
        ...state,
        isResetPassword: true,
        loading: false,
        error: false,
        message: 'reset link send'
      };
    case actions.RESETPASSWORD_ERROR:
      return {
        ...state,
        loading: false,
        error: true,
        message: action.error,
        isResetPassword: false
      };
    default:
      return state;
  }
};
